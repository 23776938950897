<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  spacing?: 'small' | 'medium' | 'large'
  justify?: 'left' | 'center' | 'right'
  align?: 'start' | 'center' | 'end'
  nowrap?: boolean
}>()

const classes = computed(() => [
  'flexlist',
  props.spacing ? `flexlist--spacing-${props.spacing}` : '',
  props.justify ? `flexlist--justify-${props.justify}` : '',
  props.align ? `flexlist--align-${props.align}` : '',
  props.nowrap ? `flexlist--nowrap` : ''
])
</script>

<style lang="scss">
.flexlist {

  --gap: #{_rem(16px)};
  &--spacing-small { --gap: #{_rem(8px)}; }
  &--spacing-large { --gap: #{_rem(32px)}; }

  display: flex;
  justify-content: flex-start;
  gap: var(--gap);

  &:not(&--nowrap) {
    flex-wrap: wrap;
  }

  &--justify-left { justify-content: flex-start }
  &--justify-center { justify-content: center }
  &--justify-right { justify-content: flex-end }

  &--align-start { align-items: flex-start }
  &--align-end { align-items: flex-end }
  &--align-center { align-items: center }
}
</style>