<template>
  <div :class="classes">
    <nuxt-link
      :to="data.link"
      :target="data.link?.startsWith('http') ? '_blank' : undefined"
      class="slide__image"
      @click="onLinkClick"
    >
      <Image :media="data.image" size="md" />
    </nuxt-link>
    <div class="slide__text">
      <nuxt-link
        :to="data.link"
        :target="data.link?.startsWith('http') ? '_blank' : undefined"
        class="slide__title"
        @click="onLinkClick"
      >
        <h3>{{ data.title }}</h3>
      </nuxt-link>
      <p v-if="dateString" class="slide__date">{{ dateString }}</p>
      <div v-if="data.description" class="slide__description" v-html="data.description" />
      <CTA v-if="data.link && data.ctaLabel" :text="data.ctaLabel" :link="path(data.link)" color="gold" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getDateString, path } from '~/lib/helpers'
import { openIframeModal } from '~/lib/modals'
import type { Slide } from '~/lib/types'

const props = defineProps<{
  data: Slide
  size?: 'narrow' | 'normal' | 'wide' | 'tall' | 'large'
}>()

const dateString = computed(() => props.data.date && getDateString(props.data.date[0], props.data.date[1] || null))

const onLinkClick = (e) => {
  if (!e.currentTarget.href && props.data.embedLink) {
    e.preventDefault()
    openIframeModal(props.data.embedLink)
  }
}

const classes = computed(() => [
  'slide',
  props.size ? `slide--size-${props.size}` : ''
])
</script>

<style lang="scss">
.slide {
  --img-aspect-ratio: 87%;
  &--size-large { --img-aspect-ratio: 76%; }
  &--size-wide { --img-aspect-ratio: 3 / 2; }
  &--size-narrow, &--size-tall { --img-aspect-ratio: 108%; }

  display: flex;
  flex-direction: column;
  width: 100%;

  a {
    cursor: pointer;
  }

  &__image {
    padding-top: var(--img-aspect-ratio);
    position: relative;
    display: block;

    img {
      @include absolute-cover;
      object-fit: cover;
      border-radius: _rem(16px);
    }
  }

  &__title {
    margin: _rem(16px) 0;
    display: inline-block;
    user-select: text;

    h3 { margin: 0 }
    &:hover { text-decoration: underline }
  }

  &__text {
    flex: 1;
    display: flex;
    flex-direction: column;

    .btn {
      width: fit-content;
    }
  }

  &__date { margin: 0 }

  &__description {
    flex: 1;
    margin: 0 0 _rem(16px);
    user-select: text;
  }
}
</style>