<template>
  <div class="membership-card">
    <h3>{{ props.title }}</h3>
    <p><strong>{{ props.subtitle }}</strong></p>
    <div class="membership-card__tags" v-if="props.tags?.length">
      <span v-for="tag in props.tags" class="tag tag--compact">
        <Icon v-if="tag.icon" :name="tag.icon" />{{ tag.text }}
      </span>
    </div>
    <div class="membership-card__content" v-html="props.content" />
    <div class="membership-card__ctas" v-if="props.ctas?.length">
      <template v-for="(item, i) in props.ctas">
        <CTA :text="item.cta" :link="path(item.cta_link)" :color="item.cta_color" outline />
      </template>
    </div>
    <div v-if="props.highlightBadge" class="membership-card__badge">{{ props.highlightBadge }}</div>
  </div>
</template>

<script lang="ts" setup>
import { path } from '~/lib/helpers'
const props = defineProps<{
  title?: string | null
  subtitle?: string | null
  tags?: { text: string, icon?: string }[] | null
  content?: string | null
  highlightBadge?: string | null
  ctas?: any[] | null
}>()
</script>

<style lang="scss">
.membership-card {
  padding: _rem(32px);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-dark-16-lines);
  border-radius: var(--box-radius-large);
  position: relative;
  user-select: text;
  margin-top: _rem(14px);

  @include breakpoint(small down) {
    padding: _rem(24px);
  }

  h3 {
    margin-bottom: _rem(14px);
  }

  &__tags {
    display: flex;
    gap: _rem(8px);
    margin: _rem(14px) 0;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: _rem(13px);
    margin-top: _rem(16px);
  }

  &__ctas {
    display: flex;
    gap: _rem(8px);
    margin-top: _rem(16px);
  }

  &__badge {
    @include caption-font;
    font-weight: 500;
    padding: _rem(4px) _rem(16px);
    border-radius: 99px;
    font-size: _rem(14px);
    text-transform: uppercase;
    color: var(--color-dark);
    background-color: var(--color-gold);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
