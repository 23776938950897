<template>
  <ul class="facts-list">
    <li v-for="item in props.items">
      <span>{{ item.label }}</span>
      <span>{{ item.value }}</span>
    </li>
  </ul>
</template>

<script lang="ts" setup>
const props = defineProps<{
  items: {
    label?: string | null,
    value?: string | null
  }[]
}>()

</script>

<style lang="scss">
.facts-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  column-gap: _rem(48px);
  row-gap: _rem(16px);
  padding: 0;
  margin: 0;

  > li {
    display: flex;
    flex-direction: column;
    gap: _rem(2px);
    text-transform: uppercase;
    position: relative;
    @include caption-font;

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      right: _rem(-24px);
      background-color: var(--color-dark-16-lines);
    }

    &:last-child::after { display: none; }

    span {
      &:first-child { font-size: _rem(14px); flex: 1; }
      &:last-child { font-size: _rem(32px); font-weight: 300; }
    }
  }
}
</style>
