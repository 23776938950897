<template>
  <div :class="classes">
    <div class="page-filter__content">
      <div class="page-filter__name">{{ props.title }}</div>
      <div class="page-filter__inputs">
        <div v-for="filter in props.filters" class="form-input">
          <SelectInput v-if="filter.type === 'select'"
            :options="getOptionsForSelect(filter)"
            :placeholder="filter.label || ''"
            @select="onFilterChange(filter, $event.value)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { SelectOption } from '~/lib/types'

export interface FilterDefinition {
  type: 'select' | 'date' // Type of input
  name: string
  label?: string // Used as placeholder or null option
  filterBy?: string | Function // Data property to filter by
  labelBy?: string | Function // Data property to use as label for select options
  nullable?: boolean
  nullLabel?: string
}

const props = defineProps<{
  title: string
  data: any[]
  filters: FilterDefinition[]
  sticky?: boolean
}>()

const emit = defineEmits(['change', 'filter'])
const state = reactive<{ [filterName: string]: any }>({})
const { t } = useI18n() 

const getOptionsForSelect = (filter: FilterDefinition) => {
  if (!props.data?.length) return []

  const filterBy = filter.filterBy || 'value'
  const labelBy = filter.labelBy || 'label'

  const opts = props.data.reduce((options, item) => {
    const key = typeof filterBy === 'string' ? item[filterBy] : filterBy(item)
    if (key) {
      options[key] = typeof labelBy === 'string' ? item[labelBy] : labelBy(item)
    }
    return options
  }, {})

  const optsArray = Object.entries(opts).map(([value, label]) => ({ value, label })) as SelectOption[]

  if (filter.nullable === undefined || filter.nullable) {
    optsArray.unshift({ value: null, label: filter.nullLabel || t('filter.all') })
  }

  return optsArray
}

const onFilterChange = (filter: FilterDefinition, value: any) => {
  const filterState = { ...filter, value }
  state[filter.name] = filterState
  emit('change', filterState)
  doFilter()
}

const doFilter = () => {
  let filteredData = props.data

  for (const filter of Object.values(state)) {
    if (!filter?.value) continue

    if (filter.type === 'select') {
      filteredData = filteredData.filter(item => {
        const key = typeof filter.filterBy === 'string' ? item[filter.filterBy || 'value'] : filter.filterBy(item)
        return key == filter.value
      })
    }
  }

  emit('filter', filteredData)
}

const classes = computed(() => ([
  'page-filter',
  { 'page-filter--sticky': props.sticky }
]))
</script>

<style lang="scss">
.page-filter {
  width: 100%;
  padding: _rem(24px) 0;
  min-height: _rem(104px);

  .anchors + &,
  .anchors + div > & {
    margin-top: _rem(-64px);
  }

  &--sticky {
    box-shadow: var(--sticky-shadow);
    padding: _rem(24px) var(--page-margin);
    background-color: var(--color-white);
    position: sticky;
    z-index: 100;
    top: 0;
  }
  
  &__content {
    max-width: var(--page-width);
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__inputs {
    display: flex;
    flex-basis: 80%;
    gap: _rem(16px);
    align-items: center;
    justify-content: flex-end;

    .form-input {
      flex: 0 1 _rem(314px);
    }
  }
}
</style>