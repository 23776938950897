<!--
  MAY THE FORCE BE WITH YOU
-->
<template>
  <template v-for="(block, index) in props.blocks">
    <template v-if="block.__typename === 'ModelListBlock'">
      <Section
        v-bind="commonSectionProps(block)"
        :title="block.number_of_featured ? block.title : undefined"
        :subtitle="block.number_of_featured ? block.description : undefined"
        :no-bottom-padding="!!block.number_of_featured"
        :fullwidth="!block.number_of_featured"
      >
        <template v-if="block.number_of_featured">
          <Spacer size="large" />
          <FlexGrid :cols="block.number_of_featured" :sm-cols="1">
            <SliderSlide v-for="item in block.models?.slice(0, block.number_of_featured)" :data="{ ...mapModelToSlide(item) }" />
          </FlexGrid>
        </template>
        <Slider v-else v-bind="(getMappedSliderProps(block) as any)" />
      </Section>
      <Section v-if="block.number_of_featured" fullwidth :background="block.background_color?.toLowerCase()" no-padding>
        <Slider v-bind="(getMappedSliderProps(block) as any)" :offset="block.number_of_featured" no-title />
        <Spacer size="large" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'TextAndImagesBlock'">
      <Section v-bind="commonSectionProps(block)">
        <TextAndImages
          :title="block.title"
          :text="block.body"
          :images="(block.images as MediaFragment[])"
          :layout="block.gallery_layout"
          :flip="!block.images_on_right"
        >
          <template v-if="block.ctas?.length" #ctas>
            <CTA v-for="cta in block.ctas" v-bind="mapCtaObject(cta)" />
          </template>
          <template v-if="block.pills?.length" #pretext>
            <FlexList spacing="small">
              <span v-for="it in block.pills" class="tag tag--compact">
                <Icon v-if="it?.icon" :name="it?.icon" />{{ it?.title }}
              </span>
            </FlexList>
            <Spacer size="small" />
          </template>
          <template v-if="block.meta?.length" #widget>
            <FactsList :items="block.meta.map(it => ({ label: it?.key, value: it?.value }))" />
          </template>
          <template v-if="block.contacts?.length && block.contacts[0]?.contact" #widget>
            <ContactWidget v-for="contact in block.contacts" v-bind="(mapContactToContactWidget(contact) as any)" />
          </template>
          <template v-if="block.include_socials && props.pageModel?.socials?.length" #widget>
            <SocialsWidget :items="props.pageModel?.socials" />
          </template>
        </TextAndImages>
        <template v-if="block.notice_text">
          <Spacer />
          <Note :text="block.notice_text" :color="block.notice_color" />
        </template>
      </Section>
    </template>

    <template v-else-if="block.__typename === 'TextSplitBlock'">
      <Section v-bind="commonSectionProps(block)">
        <SplitContent>
          <template #title>{{ block.title }}</template>
          <template #text-left><div v-html="block.content_left" /></template>
          <template #text-right><div v-html="block.content_right" /></template>
        </SplitContent>
        <template v-if="block.cta">
          <Spacer />
          <CTA v-bind="mapCtaObject(block)" />
        </template>
      </Section>
    </template>

    <template v-else-if="block.__typename === 'BannerBlock'">
      <Section v-bind="commonSectionProps(block)">
        <Banner v-bind="mapBannerBlock(block)" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'OurSightseeingsBlock'">
      <Section v-bind="commonSectionProps(block)" fullwidth>
        <Slider v-bind="mapSightseeingsToSlider(block)" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'SpellBlock'">
      <Section :title="block.title" v-bind="commonSectionProps(block)">
        <AcronymBanner :letters="(block.letters as any)" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'BigListBlock'">
      <Section :title="block.title" :subtitle="block.description" v-bind="commonSectionProps(block)">
        <Spacer v-if="!!block.title" size="small" />
        <FlexGrid :cols="2" :sm-cols="1">
          <Tile v-for="item in block.models" v-bind="mapModelToTile(item)" />
        </FlexGrid>
      </Section>
    </template>

    <template v-else-if="block.__typename === 'TileModelListBlock'">
      <PressModelList v-if="block.model === 'Press'" :data="(block.models as PressItemFragment[])" :show-filter="block.show_filter" />
      <Section v-else :title="block.title" v-bind="commonSectionProps(block)">
        <Spacer v-if="!!block.title" size="small" />
        <FlexGrid :cols="2" :sm-cols="1">
          <Tile v-for="item in block.models" v-bind="mapModelToTile(item)" />
        </FlexGrid>
      </Section>
    </template>

    <template v-else-if="block.__typename === 'RowListBlock'">
      <Section :title="block.title" v-bind="commonSectionProps(block)">
        <Spacer v-if="!!block.title" size="small" />
        <FlexGrid :cols="2" :sm-cols="1">
          <Tile v-for="item in block.items" v-bind="mapModelToTile(item)" />
        </FlexGrid>
      </Section>
    </template>

    <template v-else-if="(block.__typename === 'AccordionBlock') && block.items?.length">
      <Section v-bind="commonSectionProps(block)">
        <Accordion :title="block.title" :items="(block.items.map(it => it?.title) as string[])">
          <template v-for="(item, i) in block.items" v-slot:[`content-${i}`]>
            <MiniBlock :block="item" />
          </template>
        </Accordion>
      </Section>
    </template>

    <template v-else-if="block.__typename === 'TimelineBlock'">
      <Section v-bind="commonSectionProps(block)">
        <Timeline :title="block.title" :subtitle="block.description" :items="(block.models as any)" />
        <div v-if="block.cta" class="text-center">
          <Spacer />
          <CTA v-bind="mapCtaObject(block)" />
        </div>
      </Section>
    </template>

    <template v-else-if="block.__typename === 'LocationBlock'">
      <Section v-bind="commonSectionProps(block)">
        <TextAndImages
          :title="block.title || block.location?.title"
          :text="block.location?.perex"
          :images="(block.location?.hero as MediaFragment[])"
          :flip="!block.contentLeft"
        >
          <template #widget>
            <OpeningHours
              :data="(block.location?.openingHours as OpeningHoursFragment[])"
              :address="block.location?.address"
            />
          </template>
          <template #ctas>
            <CTA
              :text="$t('location.discover_cta') + ' ' + block.location?.title"
              :link="path(block.location?.pageable?.full_url || block.location?.slug)"
              color="gold"
            />
            <CTA v-if="block.cta" v-bind="mapCtaObject(block)" />
          </template>
        </TextAndImages>
      </Section>
    </template>

    <template v-else-if="block.__typename === 'OpeningHoursBlock'">
      <Section v-bind="commonSectionProps(block)" :title="block.title">
        <Spacer size="tiny" />
        <OpeningHoursBlock v-bind="mapOpeningHoursBlock(block)" />
        <template v-if="block.notice">
          <Spacer />
          <Note :text="block.notice" />
        </template>
      </Section>
    </template>

    <template v-else-if="block.__typename === 'GalleryBlock'">
      <Section v-bind="commonSectionProps(block)" :title="block.title" :subtitle="block.content" flat-header>
        <Spacer size="xlarge" />
        <Gallery v-if="block.images?.length" :images="(block.images as MediaFragment[])" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'StepSliderBlock'">
      <Section v-bind="commonSectionProps(block)" fullwidth>
        <StepSlider :title="block.title || ''" :steps="(block.steps as any)" :cta="block.cta || ''" :cta-link="path(block.cta_link)" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'SlideshowBlock'">
      <Section v-bind="commonSectionProps(block)">
        <Slideshow :slides="(block.items as any)" :title="block.title" display="tabs" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'QrBlock'">
      <Section :id="sanitizeAnchor(block.anchor)" fullwidth>
        <Slider v-bind="mapQrBlockToSlider(block)" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'ComparisonBlock'">
      <Section v-bind="commonSectionProps(block)" fullwidth>
        <Slider v-bind="mapComparisonBlockToSlider(block)" slide-size="narrow" centered />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'SpacerBlock'">
      <Spacer :size="block.size || 'medium'" />
    </template>

    <template v-else-if="block.__typename === 'SeparatorBlock'">
      <Separator :fullwidth="!!block.fullWidth" />
    </template>

    <template v-else-if="block.__typename === 'FormBlock'">
      <Section v-bind="commonSectionProps(block)">
        <Form v-if="block.form?.uuid" v-bind="mapFormBlockToForm(block)" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'ImageSliderBlock'">
      <Section v-bind="commonSectionProps(block)" fullwidth>
        <Slider v-bind="mapImagesToSlider(block)" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'PortfolioBlock'">
      <Section v-bind="commonSectionProps(block)" :title="block.title">
        <Spacer size="tiny" />
        <Tabs v-if="block.items?.length" :list="block.items?.map(it => it?.title || '')">
          <template v-for="item in block.items">
            <TextAndImages
              :title="item?.title"
              :text="item?.content"
              :images="([item?.image] as MediaFragment[])"
              flip
            />
          </template>
        </Tabs>
      </Section>
    </template>

    <template v-else-if="block.__typename === 'FloorAndSpacesBlock'">
      <Section v-bind="commonSectionProps(block)" :title="block.title">
        <Spacer size="small" />
        <FloorPlan :floors="block.location?.floors || []" :spaces="block.location?.spaces || []" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'SignpostBlock'">
      <Section v-bind="commonSectionProps(block)" :title="block.title">
        <Spacer v-if="!!block.title" size="small" />
        <FlexGrid :cols="2" :sm-cols="1">
          <Tile v-for="item in block.items" v-bind="mapModelToTile(item?.item?.model || {})" />
        </FlexGrid>
      </Section>
    </template>

    <template v-else-if="block.__typename === 'TextLinkTileSplitBlock'">
      <Section v-bind="commonSectionProps(block)" >
        <TextAndBoxes :title="block.title" :text="block.content || ''" :links="block.links as any || []" :flip="!!block.flip" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'RepeaterListBlock'">
      <Section v-bind="commonSectionProps(block)" fullwidth >
        <Slider v-bind="mapRepeaterToSlider(block)" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'CalendarBlock'">
      <Section v-bind="commonSectionProps(block)" >
        <CalendarBlock v-if="block.calendar_id" v-bind="mapCalendarBlock(block)" />
      </Section>
    </template>

    <template v-else-if="block.__typename === 'ModelGalleryBlock'">
      <ModelGallery v-bind="mapModelToGallery(block)" :show-filter="block.show_filter" />
    </template>

  </template>
</template>

<script lang="ts" setup>
import type { DynamicContentBlocksFragment, MediaFragment, OpeningHoursFragment, PressItemFragment } from '#gql'

import {
  mapModelToTile,
  mapModelToSlide,
  mapQrBlockToSlider,
  mapComparisonBlockToSlider,
  mapFormBlockToForm,
  mapTeamMembersToSlider,
  mapFamilyMembersToSlider,
  mapLocationsToSlider,
  mapPagesToSlider,
  mapSightseeingsToSlider,
  mapCareersToSlider,
  mapRentGroupsToSlider,
  mapTestimonialsToSlider,
  mapCollectionToSlider,
  mapImagesToSlider,
  mapEventsToSlider,
  mapRepeaterToSlider,
  mapBannerBlock,
  mapOpeningHoursBlock,
  mapContactToContactWidget,
  mapModelToGallery,
  mapPressToSlider,
  mapCtaObject,
  mapCalendarBlock
} from '~/lib/data-mappings'

import { sanitizeAnchor, path } from '~/lib/helpers'

type ModelListBlock = Extract<DynamicContentBlocksFragment, { __typename: 'ModelListBlock' }>

const props = defineProps<{
  blocks: DynamicContentBlocksFragment[]
  pageModel?: any | null
}>()

const getMappedSliderProps = (block: ModelListBlock) => {
  switch (block.model) {
    case 'Page': return mapPagesToSlider(block)
    case 'TeamMember': return mapTeamMembersToSlider(block)
    case 'FamilyMember': return mapFamilyMembersToSlider(block)
    case 'Career': return mapCareersToSlider(block)
    case 'Location': return mapLocationsToSlider(block)
    case 'Collection': return mapCollectionToSlider(block)
    case 'Testimonial': return mapTestimonialsToSlider(block)
    case 'RentGroup': return mapRentGroupsToSlider(block)
    case 'Event': return mapEventsToSlider(block)
    case 'Press': return mapPressToSlider(block)
    default: return undefined
  }
}

const commonSectionProps = (block: DynamicContentBlocksFragment) => ({
  id: 'anchor' in block ? sanitizeAnchor(block.anchor) : undefined,
  background: 'background_color' in block ? block.background_color?.toLowerCase() : undefined,
  'data-blocktype': block.__typename
})
</script>
