<template>
  <div class="opening-hours">
    <div v-if="openingHours.groups.length" class="opening-hours__time">
      <Icon name="clock" />
      <div>
        <div>
          <span class="opening-hours__status" :class="{open: openingHours.isOpen}">
            {{ openingHours.isOpen ? $t('location.open') : $t('location.closed') }}
          </span> | {{ $t('location.opening_hours') }}:
        </div>
        <div class="opening-hours__list">
          <span v-for="it in (openingHours.groups as any[])">
            {{ it.days.join('–') }}: <span class="time">{{ it.times || $t('location.closed') }}</span>
          </span>
        </div>
      </div>
    </div>
    <div v-if="props.address" class="opening-hours__location">
      <Icon name="location" />
      <a v-if="_mapLink" :href="_mapLink" target="_blank" rel="noopener noreferrer">
        <p>{{ props.address }}</p>
        <Icon name="new-window" />
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { OpeningHoursFragment } from '#gql'
import { parseOpeningHours, getMapsUrl } from '~/lib/helpers'

const props = defineProps<{
  data?: OpeningHoursFragment[] | null
  address?: string | null
  mapLink?: string | null
}>()

const _mapLink = computed(() => props.mapLink || getMapsUrl(props.address))
const openingHours = ref(parseOpeningHours(props.data || []))

watch(() => props.data, () => {
  openingHours.value = parseOpeningHours(props.data || [])
})
</script>

<style lang="scss">
.opening-hours {
  font-weight: 500;
  font-size: _rem(14px);
  width: fit-content;

  &__time, &__location {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: _rem(4px);
    padding: _rem(10px) 0;

    a {
      display: inline-flex;
      gap: _rem(4px);
      &:hover {
        color: var(--color-red);
      }
    }

    p {
      padding-top: _rem(1px);
    }
  }

  &__time {
    align-items: start;
    white-space: nowrap;
    
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: _rem(4px);
    }

    .icon {
      margin-top: _rem(-1.5px);
      min-width: _rem(24px);
    }

    .time {
      font-weight: 400;
      text-transform: lowercase;
    }
  }
    &__time + &__location {
      border-top: 1px solid var(--color-dark-16-lines);
    }

  &__status {
    color: var(--color-red);
    &.open {
      color: var(--color-green);
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    column-gap: _rem(4px);

    &:has(> :nth-child(2)) {
      flex-direction: column;
      width: 100%;
    }
  }
}
</style>