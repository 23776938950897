<template>
  <div
    class="separator"
    :class="{
      'separator--fullwidth': props.fullwidth,
      'separator--light': props.light
    }"
    role="separator"
  />
</template>

<script lang="ts" setup>
const props = defineProps<{
  fullwidth?: boolean
  light?: boolean
}>()

</script>

<style lang="scss">
.separator {
  display: block;
  border-bottom: 1px solid var(--color-dark-16-lines);
  @include page-content;

  &--fullwidth {
    width: 100%;
    max-width: 100%;
  }

  &--light {
    border-color: var(--color-light-32-lines);
  }
}
</style>