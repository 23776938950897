<template>
  <div class="calendar">
    <div v-if="props.title || props.text" class="calendar__text">
      <h2>{{ props.title }}</h2>
      <div>{{ props.text }}</div>
    </div>
    <div class="calendar__vc">
      <Calendar
        expanded
        title-position="left"
        color="hol-red"
        trim-weeks
        :locale="locale"
        :attributes="(attrs as any)"
        :initial-page="{ month: now.getMonth() + 1, year: now.getFullYear() }"
        @dayclick="onDayClick"
      />
      <Note v-if="props.note" :text="props.note" :color="props.noteColor" compact />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Calendar } from 'v-calendar'
import type { CalendarDay } from 'v-calendar/dist/types/src/utils/page.d.ts'
import 'v-calendar/style.css'
import { getTimeString } from '~/lib/helpers.js'

const props = defineProps<{
  title?: string | null
  text?: string | null
  note?: string | null
  noteColor?: string | null
  events?: {
    start: string | Date, // 'DD.MM.YYYY HH:MM'
    end?: string | Date, // 'DD.MM.YYYY HH:MM'
    note?: string
  }[]
}>()

const emit = defineEmits(['select'])
const { t, locale } = useI18n()

const now = new Date()
const selectedDate = ref<Date | null>(null)

const parseDate = (str) => {
  if (!str) return null

  const [date, time] = str.split(' ')

  const [d, m, y] = date.split('.')
  const [h, min] = (time || '').split(':')
  return new Date( y, m - 1, d, h || 0, min || 0 )
}

const groupedDates = computed(() => {
  const dates = props.events?.map(({ start, end }) => [ parseDate(start), parseDate(end) ]) || []

  return dates.reduce((acc, [start, end]) => {
    if (!start) return acc
    const startDay = start.getDate()
    const endDay = end?.getDate() || startDay

    let key = start.toLocaleDateString('sv-SE')
    if (!acc[key]) acc[key] = []
    acc[key].push({ start, end })

    if (end && startDay !== endDay) {
      key = end.toLocaleDateString('sv-SE')
      if (!acc[key]) acc[key] = []
      acc[key].push({ start, end })
    }

    return acc
  }, {})
})

const attrs = computed(() => [
  {
    key: 'selected',
    highlight: true,
    dates: selectedDate.value
  },
  ...Object.entries(groupedDates.value).map(([ key, dates ]) => ({
    highlight: {
      color: 'red',
      fillMode: 'outline'
    },
    dates: new Date(key),
    popover: {
      label: 
        `${ t('calendar.booked') }:
         ${ (dates as any[])?.map(({ start, end }) => getTimeString(start, end)).join('\n') }`,
      visibility: 'hover',
      hideIndicator: true
    }
  })) || []
])

const onDayClick = (day: CalendarDay) => {
  selectedDate.value = day.date
  emit('select', day.date)
}
</script>

<style lang="scss">
.calendar {
  display: flex;
  gap: _rem(48px);

  @include breakpoint(medium down) {
    flex-direction: column;
    gap: _rem(32px);
  }

  &__text {
    flex: 1 0 30%;
    > h2 {
      margin-bottom: _rem(24px);
    }
    > div {
      max-width: _rem(360px);
    }
  }

  &__vc {
    flex: 1 0 calc(65% - _rem(32px));
    display: flex;
    flex-direction: column;
    gap: _rem(32px);
  }

  .vc-hol-red {
    --vc-accent-600: var(--color-red);
  
    --vc-day-content-hover-bg: rgba(0, 0, 0, 0.1);
    --vc-border: var(--color-dark-16-lines);
    --vc-rounded-lg: var(--box-radius);
    --vc-header-arrow-color: var(--color-dark);
    --vc-header-arrow-hover-bg: var(--color-dark-8-bg);
    --vc-nav-hover-bg: var(--color-dark-8-bg);
    --vc-focus-ring: 0 0 0 1px var(--color-dark-16-lines); // box-shadow
    --vc-popover-content-border: var(--color-dark-16-lines);
    --vc-popover-content-bg: var(--color-white);
    --vc-nav-item-active-bg: var(--color-red);
    --vc-font-semibold: 500;
    --vc-font-bold: 500;
  }

  .vc-container {
    @include body-font;
  }

  .vc-expanded {
    .vc-header {
      margin-top: _rem(32px);
      margin-bottom: _rem(32px);
      padding: 0 3.5%;
    }

    .vc-weeks {
      display: flex;
      flex-direction: column;
      gap: _rem(16px);
      padding-bottom: _rem(32px);
      
      @include breakpoint(medium up) {
        margin: 0 -1%;
      }
    }
  }

  .vc-title {
    text-transform: capitalize;
  }

  .vc-weekday {
    text-transform: capitalize;
    color: var(--color-dark);
    font-weight: 500;
  }

  .vc-day-content {
    color: #666;
    width: _rem(32px);
    height: _rem(32px);
    font-weight: 400;
  }
    .is-today .vc-day-content {
      color: var(--color-red);
      font-weight: 600;
    }

  .vc-popover-content {
    padding: _rem(8px);
  }

  .vc-day-popover-container {
    font-weight: 500;
    white-space: pre-line;
    .vc-day-popover-header {
      display: none;
    }
  }

  .vc-highlight {
    width: _rem(32px);
    height: _rem(32px);
  }
    .vc-highlight-content-solid {
      color: var(--color-white) !important;
      box-shadow: none !important;
    }
    .vc-highlight-bg-outline {
      border-width: 1px;
    }

  .vc-arrow {
    width: 30px;
    &.vc-prev { padding-right: 2px }
    &.vc-next { padding-left: 2px }
  }

  .vc-nav-title {
    font-weight: 500;
  }

  .vc-nav-item {
    font-weight: 400;

    &.is-active:not(:focus) {
      box-shadow: none;
    }
  }
}

</style>