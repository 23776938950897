<template>
  <div class="job-card">
    <nuxt-link :to="props.link" class="job-card__title">
      <h3>{{ props.title }}</h3>
    </nuxt-link>
    <div class="job-card__tags">
      <span v-for="tag in props.tags" class="tag">{{ tag }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title: string
  link?: string
  tags: string[]
}>()
</script>

<style lang="scss">
.job-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--color-dark-16-lines);
  border-radius: _rem(16px);
  padding: _rem(32px);
  aspect-ratio: 1;

  @include breakpoint(small down) {
    padding: _rem(24px);
  }

  &__title:hover {
    text-decoration: underline;
  }

  &__tags {
    display: flex;
    gap: _rem(4px);
    margin-top: _rem(16px);
    flex-wrap: wrap;
  }
}
</style>