<template>
  <div class="text-img-widget">
    <component
      :is="props.link ? 'a' : 'div'"
      :href="props.link || undefined"
      target="_blank"
      class="text-img-widget__image"
    >
      <Image :media="props.image" size="md" />
    </component>
    <component
      :is="props.link ? 'a' : 'div'"
      :href="props.link || undefined"
      target="_blank"
      class="text-img-widget__title"
      :class="{ 'underlined-link': props.link }"
    >
      <h4>{{ props.title }} <Icon v-if="props.link" name="new-window" /></h4>
    </component>
    <div v-html="props.description" class="text-img-widget__description" />
  </div>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'

const props = defineProps<{
  title?: string | null
  link?: string | null
  description?: string | null
  image?: MediaFragment | null
}>()
</script>

<style lang="scss">
.text-img-widget {
  display: grid; 
  grid-template-columns: min(40%, 280px) 1fr; 
  grid-template-rows: min-content 1fr; 
  gap: 0 _rem(40px);
  grid-template-areas: 
    "left right"
    "left right-bottom";

  @include breakpoint(xsmall down) {
    gap: _rem(24px);
    grid-template-areas: 
      "left right"
      "bottom bottom";
  }

  &__image {
    max-width: _rem(280px);
    grid-area: left;

    img {
      width: 100%;
      height: auto;
      border-radius: var(--box-radius);
    }
  }

  &__title {
    grid-area: right;
    display: flex;

    h4 {
      display: flex;
      margin-bottom: _rem(8px);
      .icon {
        margin-left: _rem(8px);
      }
    }
  }

  &__description {
    max-width: _rem(480px);
    grid-area: right-bottom;
    
    @include breakpoint(xsmall down) {
      grid-area: bottom;
    }
  }
}
</style>