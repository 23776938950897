<template>
  <article :class="classes">
    <div class="text-n-images__content">
      <h2 v-if="props.title">{{ props.title }}</h2>
      <div v-if="slots.pretext" class="text-n-images__pretext">
        <slot name="pretext" />
      </div>
      <RichText v-if="props.text" :text="props.text" />
      <div v-if="slots.widget" class="text-n-images__widget">
        <slot name="widget" />
      </div>
      <div v-if="slots.ctas" class="text-n-images__ctas">
        <slot name="ctas" />
      </div>
    </div>

    <!-- Gallery layouts -->
    <aside v-if="!hasParallax && props.images && shownImages?.length" class="text-n-images__gallery-wrap">
      <div
        class="text-n-images__gallery"
        :class="{ 'has-more': hasMoreImages }"
        :style="`--extra-images-count: ${props.images.length - shownImages.length}`"
      >
        <div>
          <div
            v-for="(item, i) in shownImages"
            class="text-n-images__img"
            @click="() => (!isVideo(item) || shownImages.length > 1) && openGalleryModal(props.images || null, i)"
            v-intersect
          >
            <VideoPlayer v-if="isVideo(item)" :url="item.original_url || ''"
              :disable-controls="shownImages.length > 1"
            />
            <Image v-else :media="item" :size="shownImages.length > 1 ? 'md' : 'lg'" />
          </div>
        </div>
      </div>
    </aside>

    <!-- Parallax layouts -->
    <template v-else-if="hasParallax">
      <aside v-if="shownImages[0]" class="text-n-images__parallax-item" v-parallax="{ speed: 0.6, mobileSpeed: 0.4 }">
        <Image :media="shownImages[0]" size="md" />
      </aside>
      <aside v-if="shownImages[1]" class="text-n-images__parallax-item" v-parallax="{ speed: 0.8, mobileSpeed: 1.5 }">
        <Image :media="shownImages[1]" size="md" />
      </aside>
      <aside v-if="shownImages[2]" class="text-n-images__parallax-item" v-parallax="{ mobileSpeed: 2.5 }">
        <Image :media="shownImages[2]" size="md" />
      </aside>
    </template>
  </article>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'
import { openGalleryModal } from '~/lib/modals'

const props = defineProps<{
  title?: string | null
  text?: string | null
  images?: MediaFragment[] | null
  layout?: string | null
  flip?: boolean
}>()

const slots = useSlots()

const computedLayout = computed(() => {
  if (props.layout?.includes('parallax')) {
    return props.layout
  } else if (props.images?.length !== undefined && props.images?.length < 3) {
    return props.images?.length === 1 ? 'full' : 'top-bottom' // 1 or 2 images
  } else {
    return props.layout ? props.layout : 'top' // 3 and more
  }
})

const shownImages = computed(() => props.images?.slice(0, 3) || [])
const hasMoreImages = computed(() => props.images?.length && (props.images.length > shownImages.value?.length))
const hasParallax = computed(() => props.layout && props.layout.includes('parallax') )

const isVideo = (media: MediaFragment) => media.mime_type?.startsWith('video/')

const classes = computed(() => [
  'text-n-images',
  `layout-${computedLayout.value}`,
  props.flip ? 'text-n-images--flip' : '',
  hasParallax.value ? 'text-n-images--with-parallax' : '',
])
</script>

<style lang="scss">
.text-n-images {
  display: flex;
  gap: var(--grid-double-gap);

  @include breakpoint(small down) {
    flex-wrap: wrap;
  }

  &--flip {
    @include breakpoint(medium up) {
      flex-direction: row-reverse;
    }
  }

  &--with-parallax {
    display: grid;
    gap: var(--grid-gap);

    @include breakpoint(small down) {
      display: block;
      padding-bottom: 100%;
    }

    &.layout-parallax-a {
      grid-template-columns: 1.5fr 1fr;
      grid-template-rows: 0.8fr 1fr;
      grid-template-areas: 
        "top-left right"
        "bottom-left right";
      @include breakpoint(large down) {
        grid-template-rows: 0.9fr 1fr;
      }
      @include breakpoint(medium down) {
        grid-template-rows: 1.2fr 1fr;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 calc(50% - var(--grid-double-gap) / 2);

    @include breakpoint(small down) {
      flex: 1 0 100%;
    }

    > div {
      max-width: _rem(496px);
    }
  }
    &--flip &__content {
      @include breakpoint(medium up) {
        align-items: end;
        > div, h2 {
          width: 100%;
          max-width: _rem(496px);
        }
      }
    }
    &--with-parallax &__content {
      justify-content: start;
    }

  &__widget {
    margin-top: _rem(36px);
  }

  &__ctas {
    display: flex;
    flex-wrap: wrap;
    gap: _rem(16px);
    margin-top: _rem(24px);
  }

  &__gallery-wrap {
    flex: 1 0 calc(50% - var(--grid-double-gap) / 2);
    @include breakpoint(small down) {
      flex: 1 0 100%;
      margin-top: _rem(16px);
    }
  }

  &__gallery {
    position: relative;
    padding-top: 100%;

    > div {
      top: 0;
      display: grid;
      position: absolute;
      width: 100%;
      height: 100%;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: calc(50% - var(--grid-gap) / 2) calc(50% - var(--grid-gap) / 2);
      gap: var(--grid-gap);
    }

    .layout-full & > div {
      display: block;
    }
    .layout-top & > div {
      grid-template-areas: 
        "top top"
        "bottom-left bottom-right";
    }
    .layout-bottom & > div {
      grid-template-areas: 
        "top-left top-right"
        "bottom bottom";
    }
    .layout-left & > div {
      grid-template-areas: 
        "left top-right"
        "left bottom-right";
    }
    .layout-right & > div {
      grid-template-areas: 
        "top-left right"
        "bottom-left right";
    }
    .layout-top-bottom & > div {
      grid-template-columns: 1fr;
      grid-template-areas: "top" "bottom";
    }
  }

  &__img {
    cursor: pointer;
    position: relative;
    display: flex;

    &:nth-child(1) {
      @include animation-slide-fade-in;
      grid-area: top;
      .layout-left & { grid-area: left }
      .layout-right & { grid-area: top-left }
      .layout-bottom & { grid-area: top-left }
      .layout-full & { height: 100% }
    }
    &:nth-child(2) {
      @include animation-slide-fade-in(right);
      grid-area: bottom-left;
      .layout-left & { grid-area: top-right }
      .layout-right & { grid-area: right }
      .layout-bottom & { grid-area: top-right }
      .layout-top-bottom & { grid-area: bottom }
    }
    &:nth-child(3) {
      @include animation-slide-fade-in(left);
      grid-area: bottom-right;
      .layout-right & { grid-area: bottom-left }
      .layout-bottom & { grid-area: bottom }
    }

    img, video {
      object-fit: cover;
      object-position: center;
      border-radius: var(--box-radius);
      width: 100%;
      height: 100%;
    }

    picture {
      width: 100%;
      height: 100%;
    }

    .has-more &:last-child::after {
      counter-reset: count var(--extra-images-count);
      content: "+" counter(count);
      position: absolute;
      right: 0;
      bottom: 0;
      width: 75%;
      height: 75%;
      display: flex;
      align-items: end;
      justify-content: end;
      padding: 0 _rem(24px) _rem(24px) 0;
      color: var(--color-white);
      background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.25) 0%, transparent 50%);
      transition: all 300ms ease;
      border-bottom-right-radius: var(--box-radius);
      @include caption-font;
    }
    .has-more &:last-child:hover::after {
      width: 100%;
      height: 100%;
    }
  }

  &__parallax-item {
    display: flex;

    img {
      object-fit: cover;
      border-radius: var(--box-radius);
      height: auto;
    }

    .layout-parallax-a & {
      &:nth-of-type(1) {
        grid-area: right;
        align-items: start;
        justify-content: end;
  
        @include breakpoint(small down) {
          width: 60%;
          right: 0;
          bottom: 12%;
          position: absolute;
        }
  
        img {
          width: 80%;
          aspect-ratio: 0.75;
        }
      }
  
      &:nth-of-type(2) {
        grid-area: bottom-left;
        align-items: end;
        justify-content: start;
  
        @include breakpoint(small down) {
          width: 45%;
          left: 0;
          bottom: 45vw;
          position: absolute;
        }
  
        img { 
          width: 95%;
          aspect-ratio: 1.48;
        }
      }
  
      &:nth-of-type(3) {
        grid-area: right;
        align-items: end;
        justify-content: end;
  
        @include breakpoint(small down) {
          width: 40%;
          left: 5%;
          bottom: 5%;
          position: absolute;
        }
  
        img {
          width: 90%;
          max-width: _rem(288px);
          aspect-ratio: 1.48;
        }
      }
    }

  }
}
</style>