<template>
  <div :class="classes" v-intersect>
    <div class="slider__header">
      <h2 v-if="!props.noTitle" class="slider__title">{{ props.title }}</h2>
      <div v-if="props.subtitle && !props.noTitle" class="slider__subtitle">{{ props.subtitle }}</div>
      <nuxt-link
        v-if="props.moreLink && props.moreLabel && props.display !== 'cards'"
        :to="props.moreLink"
        class="btn"
        :class="hasRedControls ? 'btn--red' : 'btn--outline'"
      >
        {{ props.moreLabel }}
      </nuxt-link>
      <div class="slider__arrows" v-if="props.display === 'cards' && isScrollable">
        <button class="btn btn--outline btn--circle btn--arrow-left" @click="() => changeSlide(true)">
          <Icon name="arrow-left" size="small" />
        </button>
        <button class="btn btn--outline btn--circle btn--arrow-right" @click="() => changeSlide()">
          <Icon name="arrow-right" size="small" />
        </button>
      </div>
    </div>

    <div v-if="props.slides"
      ref="itemsWrapper"
      class="slider__items"
      @scroll="handleScroll"
      role="list"
    >
      <div v-for="slide in ((props.offset || 0) > 0 ? props.slides.slice(props.offset) : props.slides)"
        :class="'slider__item slider__item--' + props.slideSize"
        role="listitem"
      >
        <Card v-if="props.display === 'cards'"
          :image="slide.image"
          :pretitle="slide.pretitle"
          :title="slide.title || ''"
          :description="slide.description"
          :link="slide.link"
          :footerComponent="slide.footerComponent"
          :size="props.slideSize"
          :clamp-description="slide.clampDescription"
          :text-only="slide.textOnly"
        />
        <JobCard v-else-if="props.display === 'jobs'"
          :title="slide.title || ''"
          :link="slide.link"
          :tags="slide.tags || []"
        />
        <DonationCard v-else-if="props.display === 'donations'"
          :title="slide.title || ''"
          :description="slide.description"
          :image="slide.image"
        />
        <Testimonial v-else-if="props.display === 'testimonials'"
          :name="slide.title"
          :tags="slide.tags || []"
          :avatar="slide.image"
          :rating="slide.rating || 0"
          :date="(slide.date as string)"
          :text="slide.description || ''"
        />
        <MembershipCard v-else-if="props.display === 'memberships'"
          v-bind="(slide as any)"
        />
        <component v-else-if="props.display === 'images'"
          :is="slide.link ? 'a' : 'div'"
          :href="slide.link || undefined"
          class="slider__img-slide"
        >
          <Image :media="slide.image" size="sm" :resize="0.5" />
        </component>
        <SliderSlide v-else
          :data="slide"
          :size="props.subtitle ? 'narrow' : props.slideSize"
        />
      </div>
    </div>

    <div v-if="isScrollable && !props.noScrollbar"
      ref="scrollbarTrack"
      class="slider__scrollbar"
      :class="{ 'visible': isInitialized }"
    >
      <div
        ref="scrollbarThumb"
        class="slider__scrollbar-thumb"
        :class="{ 'slider__scrollbar-thumb--red': hasRedControls, 'is-grabbed': isThumbGrabbed }"
        :style="{ width: `${thumbWidth}%` }"
        @mousedown="handleThumbGrab"
      />
    </div>

    <div class="slider__mobile-footer">
      <nuxt-link v-if="props.moreLink && props.moreLabel"
        :href="props.moreLink"
        class="btn btn--outline"
      >
        {{ props.moreLabel }}
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Slide } from '~/lib/types'

const props = defineProps<{
  title?: string | null
  subtitle?: string | null
  moreLink?: string | null
  moreLabel?: string | null
  noScrollbar?: boolean
  noTitle?: boolean
  centered?: boolean
  display?: string // 'slides' | 'cards' | 'jobs' | 'testimonials' | 'donations' | 'images'
  slideSize?: 'narrow' | 'wide' | 'tall' | 'large'
  slides?: Slide[]
  offset?: number
}>()

const itemsWrapper = ref<HTMLElement | null>(null)
const scrollbarTrack = ref<HTMLElement | null>(null)
const scrollbarThumb = ref<HTMLElement | null>(null)
const hasRedControls = ref<boolean>(false)
const isInitialized = ref<boolean>(false)
const isThumbGrabbed = ref<boolean>(false)
const isScrollable = ref<boolean>(true)

const scrollbarScale = 1

const classes = computed(() => [
  'slider',
  `slider--display-${ props.display || 'slides' }`,
  props.subtitle && !props.noTitle ? 'slider--with-preslide' : '',
  props.centered ? 'slider--centered' : '',
  isScrollable.value ? 'scrollable' : ''
])

onMounted(() => {
  init()
  window.addEventListener('resize', init)
  hasRedControls.value = !!itemsWrapper.value?.closest('.section--gold')

})

let scrollbarTicking = false
let thumbOffset = 0
let maxThumbOffset = 0
let thumbWidth = 0
let trackWidth = 0

const init = () => {
  isScrollable.value = (itemsWrapper.value?.scrollWidth || 0) > (itemsWrapper.value?.clientWidth || 0)

  if (props.noScrollbar) return

  trackWidth = scrollbarTrack.value?.clientWidth || 0
  thumbWidth = scrollbarScale * 100 * (itemsWrapper.value?.clientWidth || 0) / (itemsWrapper.value?.scrollWidth || 1)
  maxThumbOffset = trackWidth - (thumbWidth / 100) * trackWidth
  updateScrollbar()
  isInitialized.value = true
}

const updateScrollbar = () => {
  if (!itemsWrapper.value) return
  const percentScrolled = (itemsWrapper.value.scrollLeft / (itemsWrapper.value.scrollWidth - itemsWrapper.value.clientWidth))
  thumbOffset =  Math.round( trackWidth * (1 - thumbWidth / 100) * percentScrolled * 10 ) / 10

  scrollbarThumb.value && (
    scrollbarThumb.value.style.transform = `translateX(${thumbOffset}px)`
  )
}

const handleScroll = () => {
  if (scrollbarTicking || isThumbGrabbed.value) return

  window.requestAnimationFrame(() => {
    updateScrollbar()
    scrollbarTicking = false
  })

  scrollbarTicking = true
}

const handleThumbGrab = (e: MouseEvent) => {
  const initialX = e.clientX
  const initialThumbOffset = thumbOffset
  const abortController = new AbortController()

  isThumbGrabbed.value = true
  itemsWrapper.value?.classList.add('is-controlled')

  // on drop
  window.addEventListener('mouseup', () => {
    abortController.abort()
    isThumbGrabbed.value = false
    itemsWrapper.value?.classList.remove('is-controlled')
  })

  // on drag
  window.addEventListener('mousemove', e => {
    if (scrollbarTicking || !isThumbGrabbed.value) return

    window.requestAnimationFrame(() => {
      const delta = e.clientX - initialX
      const relativeDelta = delta / trackWidth
  
      const offset = initialThumbOffset + relativeDelta * trackWidth
      thumbOffset = Math.round( Math.min(Math.max(offset, 0), maxThumbOffset) * 10 ) / 10
  
      scrollbarThumb.value && (
        scrollbarThumb.value.style.transform = `translateX(${thumbOffset}px)`
      )

      itemsWrapper.value?.scrollTo({
        left: thumbOffset * itemsWrapper.value?.scrollWidth / trackWidth,
        behavior: 'instant'
      })

      scrollbarTicking = false
    })

    scrollbarTicking = true
  }, { signal: abortController.signal })
}

const changeSlide = (reverse?: boolean) => {
  if (!itemsWrapper.value) return
  const slideWidth = itemsWrapper.value.firstElementChild?.clientWidth || 0
  const direction = reverse ? -1 : 1
  itemsWrapper.value.scrollBy({ left: (slideWidth + 16) * direction, behavior: 'smooth' })
}
</script>

<style lang="scss">
.slider {
  --items-gap: var(--grid-gap);
  $page-side-space: max( calc((100vw - var(--page-width)) / 2), var(--page-margin) );

  &--with-preslide {
    @include breakpoint(large up) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__header {
    @include page-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      margin: 0;
      max-width: 600px;
    }

    .btn {
      @include breakpoint(small down) {
        display: none;
      }
    }
  }
    &--with-preslide &__header {
      @include breakpoint(large up) {
        width: calc(_rem(390px) + var(--items-gap));
        margin-left: $page-side-space;
        padding-right: var(--items-gap);
        padding-bottom: _rem(50px);
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

  &__arrows {
    display: flex;
    gap: _rem(4px);
  }

  &__subtitle {
    flex: 1;
    margin-top: _rem(16px);
    max-width: _rem(340px);
    @include breakpoint(medium down) {
      order: 1;
      flex-basis: 100%;
      max-width: _rem(580px);
      margin-bottom: _rem(24px);
    }
  }

  &__items {
    flex: 1;
    display: flex;
    gap: var(--items-gap);
    padding-top: _rem(32px);
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-padding-left: $page-side-space;
    scroll-padding-right: $page-side-space;
    padding-right: $page-side-space;
    user-select: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar { display: none }
    &:not(.is-controlled) { scroll-snap-type: x mandatory }
  }
    &--with-preslide &__items {
      padding-top: 0;
      @include breakpoint(large up) {
        scroll-padding-left: 0;
      }
    }
    &--display-images &__items {
      align-items: flex-start;
    }
    &--centered:not(.scrollable) &__items {
      justify-content: center;
    }

  &__item {
    flex: 1 0 calc(100% - var(--page-margin));
    max-width: min(_rem(392px), 80%);
    position: relative;
    display: flex;
    scroll-snap-align: start;
    transform: translate3d(-10%, 0, 0) scale(0.95);
    opacity: 0;

    @include breakpoint(small down) {
      scroll-snap-align: center;
    }

    [data-animated] & {
      @include generate-delays(8, 100ms);
      opacity: 1;
      transform: none;
      transition:
        opacity 300ms linear calc(var(--delay, 0ms) + 200ms),
        transform 800ms var(--ease-smooth-in) var(--delay, 0ms);
    }

    &--narrow { max-width: _rem(288px) }
    &--large { max-width: _rem(552px) }
    &--wide { max-width: _rem(600px) }

    &:first-child { margin-left: $page-side-space }
    &:last-child { scroll-snap-align: end }

    > div {
      width: 100%;
    }
  }
    &__item:has(#{&}__img-slide) {
      flex: 0;
      margin-top: _rem(16px);
      img { max-width: _rem(392px); }

      &:not(:last-child) {
        margin-right: var(--grid-double-gap);
      }
    }
    @include breakpoint(large up) {
      &--with-preslide &__item:first-child {
        margin-left: 0;
      }
      &--with-preslide &__item:last-child {
        scroll-snap-align: start;
      }
    }
  
  &__scrollbar {
    @include page-content;
    height: 8px;
    background-image: linear-gradient(to top, transparent 0px, transparent 3px, var(--color-dark-16-lines) 3px, var(--color-dark-16-lines) 5px, transparent 5px, transparent 8px);
    opacity: 0;
    transition: opacity 150ms;
    margin-top: _rem(50px);

    &.visible { opacity: 1 }

    &-thumb {
      height: 8px;
      background: var(--color-gold);
      border-radius: 99px;

      &--red { background: var(--color-red) }
    }
  }

  &__mobile-footer {
    display: none;
    text-align: center;
    margin-top: _rem(50px);

    @include breakpoint(small down) {
      display: block;
    }
  }
}
</style>
