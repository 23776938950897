<template>
  <component
    :class="classes"
    :is="props.clickable && props.link ? 'a' : 'div'"
    :href="props.clickable && props.link || undefined"
    :target="props.link?.startsWith('http') ? '_blank' : undefined"
  >
    <div v-if="props.pretitle" class="tile__pretitle">{{ props.pretitle }}</div>

    <component
      class="tile__title"
      :class="{ link: props.link }"
      :is="props.link ? 'a' : 'div'"
      :href="props.link || undefined"
      :target="props.link?.startsWith('http') ? '_blank' : undefined"
    >
      <h3>{{ props.title }}</h3>
    </component>

    <div class="tile__content">
      <RichText :text="props.content" />
    </div>

    <div class="tile__extra">
      <slot />
    </div>

    <nuxt-link v-if="props.link"
      :href="props.link"
      class="tile__arrow btn btn--white btn--circle"
    >
      <Icon name="arrow-forward" size="small" />
    </nuxt-link>
  </component>
</template>

<script lang="ts" setup>
const props = defineProps<{
  link?: string | null
  clickable?: boolean
  pretitle?: string | null
  title?: string | null
  content?: string | null
}>()

const classes = computed(() => [
  'tile',
  props.clickable ? 'tile--clickable' : '',
])
</script>

<style lang="scss">
.tile {
  display: block;
  padding: _rem(32px) 0;
  border-top: 1px solid var(--color-dark-16-lines);
  position: relative;

  &__pretitle {
    @include caption-font;
    text-transform: uppercase;
    margin-bottom: _rem(24px);
  }

  &__title {
    display: block;
    margin-bottom: _rem(8px);
  }

  &__content {
    max-width: _rem(400px);
  }

  &__extra:not(:empty) {
    margin-top: _rem(24px);
  }

  &__arrow {
    top: 50%;
    right: 0;
    position: absolute;
    transform: translateY(-50%) scale(0.95);
    opacity: 0;
    transition: all 100ms ease;
  }
    .flexgrid > & &__arrow {
      top: calc(50% + _rem(12px));
    }
    @media (hover: hover) {
      &:hover &__arrow {
        opacity: 1;
        transform: translateY(-50%);
      }
    }

  &--clickable {
    cursor: pointer;
    &:hover {
      .tile__title {
        text-decoration: underline;
      }
    }
  }
}
</style>