<template>
  <div class="contact-widget">
    <h4 class="contact-widget__pretitle" v-if="props.pretitle">{{ props.pretitle }}</h4>
    <div class="contact-widget__person">
      <Image v-if="props.avatar" class="contact-widget__avatar" :media="props.avatar" size="xs" />
      <div class="contact-widget__info">
        <p class="contact-widget__name">{{ props.name }}</p>
        <p class="contact-widget__role" v-if="props.role">{{ props.role }}</p>
        <p class="contact-widget__contact" v-if="props.contactValue">
          <Icon :name="props.contactIcon || 'email'" />
          {{ props.contactValue }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'

const props = defineProps<{
  pretitle?: string | null
  name: string | null
  role?: string | null
  contactIcon?: string | null
  contactValue: string | null
  avatar: MediaFragment | null
}>()
</script>

<style lang="scss">
.contact-widget {
  &__pretitle {
    @include caption-font;
    font-size: _rem(14px);
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 _rem(22px);
  }

  &__person {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: _rem(16px);
    flex: 1;
    position: relative;

    &:has(.contact-widget__contact) {
      padding-bottom: _rem(22px);
      &:not(:has(.contact-widget__avatar)) {
        padding-bottom: _rem(28px);
      }
    }
  }

  &__avatar {
    width: _rem(80px);
    height: _rem(80px);
    border-radius: 999px;
  }

  &__info {
    p {
      margin: 0;
    }
  }

  &__name {
    font-weight: 500;
    font-size: _rem(18px);
  }

  &__role {
    font-size: _rem(14px);
  }

  &__contact {
    display: flex;
    align-items: center;
    gap: _rem(8px);
    font-size: _rem(14px);
    font-weight: 500;
    white-space: nowrap;
    position: absolute;
    bottom: 0;
  }

  & + & {
    margin-top: _rem(32px);
    padding-top: _rem(32px);
    border-top: 1px solid var(--color-dark-16-lines);
  }
}
</style>