<template>
  <div class="banner" :class="{ 'banner--transparent': props.transparent }">
    <div class="banner__inner">
      <div class="banner__text">
        <h2 v-if="props.title">{{ props.title }}</h2>
        <RichText :text="props.content" />
      </div>
      <div class="banner__person" v-if="props.personName">
        <Image v-if="props.personImage" class="banner__person-img" :media="props.personImage" size="xs" />
        <div class="banner__person-info">
          <p>{{ props.personName }}</p>
          <p v-if="props.personRole">{{ props.personRole }}</p>
        </div>
      </div>
      <div class="banner__cta" v-if="props.cta?.text">
        <CTA v-bind="props.cta" />
      </div>
      <div class="banner__contact" v-else-if="personPhone || personEmail">
        <div>
          <a class="banner__phone" v-if="personPhone" :href="'tel:'+personPhone"><Icon name="phone" />{{ personPhone }}</a>
          <a class="banner__mail" v-if="personEmail" :href="'mailto:'+personEmail"><Icon name="mail" />{{ personEmail }}</a>
        </div>
      </div>
    </div>
    <Note class="banner__subcontent" v-if="props.subContent" :text="props.subContent" color="dark-grey" compact />
  </div>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'
import type { CTAProps } from '~/lib/types'
import { path } from '~/lib/helpers'

const props = defineProps<{
  title?: string | null
  content?: string | null
  subContent?: string | null
  cta?: CTAProps | null
  personName?: string | null
  personRole?: string | null
  personPhone?: string | null
  personEmail?: string | null
  personImage?: MediaFragment | null
  transparent?: boolean
}>()

const emit = defineEmits(['cta-click'])
</script>

<style lang="scss">
.banner {
  padding: _rem(48px);
  border-radius: var(--box-radius);
  background-color: var(--color-dark-8-bg);
  color: var(--color-dark);

  &--transparent {
    color: var(--color-white);
    background-color: var(--color-light-16-bg);
  }

  @include breakpoint(medium down) {
    padding: _rem(32px);
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: _rem(24px);

    @include breakpoint(small down) {
      flex-direction: column;
      align-items: start;
    }

    @include breakpoint(medium down) {
      &:has(.banner__person), &:has(.banner__form) {
        flex-direction: column;
        align-items: start;
      }
    }
  }

  &__text {
    flex: 2;

    h2 {
      font-size: _rem(32px);
    }
  }

  &__person {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    gap: _rem(16px);
    flex: 1;

    &-img {
      width: _rem(80px);
      height: _rem(80px);
      border-radius: 999px;
      object-fit: cover;
    }

    &-info {
      p {
        margin: 0;
        &:first-child {
          font-weight: 500;
          font-size: _rem(18px);
        }
        &:last-child {
          font-size: _rem(14px);
        }
      }
    }
  }

  &__cta {
    flex: 1;
    text-align: right;
  }

  &__contact {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__phone, &__mail {
    display: flex;
    align-items: center;
    gap: _rem(10px);
    padding: _rem(6px) 0;
    font-size: _rem(14px);
    font-weight: 500;
    color: var(--color-dark);
    text-decoration: none;
  }
    &__phone + &__mail {
      border-top: 1px solid var(--color-dark-16-lines);
    }

  &__subcontent {
    width: 100%;
    margin-top: _rem(60px);
    font-size: _rem(14px);
  } 
}
</style>