<template>
  <div :class="classes">
    <component v-if="!props.textOnly"
      :is="linkOrDiv"
      :to="props.link"
      :target="props.link?.startsWith('http') ? '_blank' : undefined"
      class="card__image"
    >
      <Image :media="props.image" />
    </component>
    <div class="card__text">
      <p class="card__pretitle" v-if="props.pretitle">{{ props.pretitle }}</p>
      <component
        :is="linkOrDiv"
        :to="props.link"
        :target="props.link?.startsWith('http') ? '_blank' : undefined"
        class="card__title"
      >
        <h3>{{ props.title }}</h3>
      </component>
      <div class="card__desc" v-if="props.description" v-check-overflow>
        <div v-if="!props.textOnly" class="card__desc-short" v-html="props.description" aria-hidden="true" />
        <div class="card__desc-long" v-html="props.description" v-check-overflow />
        <a href="" class="underlined-link" @click.prevent="openDetail">{{ $t('content.more') }}</a>
      </div>
      <div class="card__footer" v-if="props.footerComponent">
        <!-- <slot name="footer" /> -->
        <component :is="props.footerComponent" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'
import { openModal } from '~/lib/modals'

const props = defineProps<{
  image?: MediaFragment
  title: string
  pretitle?: string
  description?: string
  clampDescription?: boolean
  link?: string
  footerComponent?: any
  size?: string
  textOnly?: boolean
}>()

const classes = computed(() => [
  'card',
  props.size ? 'card--' + props.size : '',
  props.clampDescription ? 'card--clamp-description' : '',
  props.textOnly ? 'card--text-only' : ''
])

const linkOrDiv = computed(() => props.link ? resolveComponent('NuxtLink') : 'div')

const openDetail = () => {
  openModal(props.title, props.description || '')
}
</script>

<style lang="scss">
.card {
  border-radius: var(--box-radius);
  user-select: text;
  aspect-ratio: 14 / 15;
  
  &--wide {
    aspect-ratio: 3 / 2;
  }

  --padding: #{_rem(32px)};
  @include breakpoint(small down) {
    --padding: #{_rem(24px)};
  }

  &--text-only {
    background-color: var(--color-dark-8-bg);
  }

  &:hover a { text-decoration: underline }

  &__image {
    display: block;
    height: 100%;

    picture {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: var(--box-radius);
    }

    &::before, &::after {
      content: '';
      @include absolute-cover;
      border-radius: var(--box-radius);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.1) 100%);
    }

    &::after {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
      mix-blend-mode: overlay;
    }
  }

  &__text {
    bottom: var(--padding);
    left: var(--padding);
    width: calc(100% - var(--padding) * 2);
    position: absolute;
    pointer-events: none;
    color: var(--color-white);
  }
    &--text-only &__text {
      color: var(--color-dark);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: static;
      padding: var(--padding);
      width: 100%;
      height: 100%;
      pointer-events: all;
    }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: _rem(16px);
  }

  &__pretitle { 
    font-size: _rem(14px);
    font-weight: 500;
    margin-bottom: _rem(8px);
  }

  &__title h3 { margin: 0 }

  &__desc {
    margin: _rem(8px) 0 0;

    &-short {
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      position: absolute;
      display: none;
    }
      &.is-overflown-v &-short {
        display: -webkit-box;
      }

    &-long {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      line-clamp: 7;
      @include with-more-text;
    }
      &.is-overflown-v &-long {
        opacity: 0;
        transition: opacity 150ms ease;
      }
  }
    &--clamp-description &__desc {
      overflow: hidden;
      max-height: 3ex;
      transition: all 300ms ease;
    }
    &--clamp-description:hover &__desc {
      max-height: 21ex;
      &-short { opacity: 0; }
      &-long { opacity: 1; }
    }
}
</style>