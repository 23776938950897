<template>
  <picture>
    <source media="(max-width: 434px)" :srcset="getSrcset('sm')" />
    <source media="(min-width: 435px)" :srcset="getSrcset(props.size || 'md')" />
    <img
      :src="props.media?.original_url || ''"
      :alt="props.alt || ''"
      :class="$attrs.class"
      v-resize-img="resize"
    />
  </picture>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'

defineOptions({ inheritAttrs: false })

const props = defineProps<{
  media?: MediaFragment | null
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  resize?: number // resize relatively to image naturalWidth (0-1)
  alt?: string
}>()

// size: [ @x1, @x2 ]
const sizes = {
  xs: [ 392, 392 ],
  sm: [ 392, 784 ],
  md: [ 784, 1200 ],
  lg: [ 1200, 1920 ],
  xl: [ 1920, 3840 ]
}

const getSrcset = (size: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  if (!props.media) return undefined

  const [ low, high ] = sizes[size]
  const lowImage = props.media?.responsive_images?.find( img => img?.size === `${low}` )?.url
  const highImage = props.media?.responsive_images?.find( img => img?.size === `${high}` )?.url

  return lowImage && highImage
    ? `${lowImage}, ${highImage} 2x`
    : undefined
}
</script>
