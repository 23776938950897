<template>
  <div
    class="gallery"
    :class="{ 'has-more': remaining?.length }"
    :style="`--extra-images-count: ${remaining?.length || 0}`"
  >
    <div v-for="(col, i) in cols" class="gallery__col">
      <div v-for="image in col" class="gallery__item" @click="() => onImgClick(image)">
        <Image :media="image" size="sm" />
      </div>
      <!-- <div v-if="remaining?.length && (i === cols.length - 1)"
        class="gallery__more"
        @click="onImgClick(remaining[0])"
      >
        +{{ remaining.length }}
      </div> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { openGalleryModal } from '~/lib/modals'
import type { MediaFragment } from '#gql'

const props = defineProps<{
  images?: MediaFragment[]
  imageCount?: number
  columns?: number
}>()

const colCount = ref(props.columns || 3)
const imgCountCeiling = ref(props.imageCount || colCount.value * 2)

const displayedImages = computed( () => props.images?.slice(0, Math.min(props.images.length, imgCountCeiling.value)) || [] )
const thirdOfImages = computed( () => Math.ceil(displayedImages.value.length / colCount.value) )

const cols = computed(() => props.images?.length
  ? new Array(colCount.value)
      .fill(0)
      .map( (_, i) => displayedImages.value.slice(i * thirdOfImages.value, (i + 1) * thirdOfImages.value) )
  : []
)

const remaining = computed( () => props.images?.slice(displayedImages.value.length) )

const onImgClick = (image: MediaFragment) => openGalleryModal(props.images || null, props.images?.indexOf(image))
</script>

<style lang="scss">
.gallery {
  display: flex;
  gap: var(--grid-gap);

  &__col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: var(--grid-gap);

    &:last-child div { 
      .has-more &:last-child::after {
        counter-reset: count var(--extra-images-count);
        content: "+" counter(count);
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: end;
        justify-content: end;
        padding: 0 _rem(24px) _rem(24px) 0;
        color: var(--color-white);
        background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.25) 0%, transparent 35%);
        transition: all 300ms ease;
        border-bottom-right-radius: var(--box-radius);
        pointer-events: none;
        @include caption-font;
      }
      .has-more &:last-child:hover::after {
        width: 150%;
        height: 150%;
      }
    }
  }

  &__item {
    position: relative;

    img {
      width: 100%;
      height: auto;
      border-radius: var(--box-radius);
      cursor: pointer;
    }
  }

  &__more {
    @include caption-font;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 2.9;
    border-radius: var(--box-radius);
    background: var(--color-dark);
    color: white;
    transition: opacity 150ms ease;
    cursor: pointer;

    &:hover { opacity: 0.7; }
  }
}
</style>
