<template>
  <div class="testimonial">
    <div class="testimonial__person">
      <Image class="testimonial__avatar" :media="props.avatar" size="xs" />
      <div class="testimonial__details">
        <div class="testimonial__name">{{ props.name }}</div>
        <div class="testimonial__rating">
          <Icon :name="i <= props.rating ? 'star' : 'star-outline'" v-for="i in 5" size="small" />
        </div>
        <client-only>
          <div class="testimonial__when">{{ when }}</div>
        </client-only>
      </div>
    </div>
    <!-- <div class="testimonial__tags">
      <div v-for="tag in props.tags" class="tag tag--compact">{{ tag }}</div>
    </div> -->
    <div class="testimonial__text" v-html="props.text" />
  </div>
</template>

<script lang="ts" setup>
import { format, register as registerTimeAgoLocale } from 'timeago.js'
import csTimeAgo from 'timeago.js/lib/lang/cs'
import type { MediaFragment } from '#gql'

const props = defineProps<{
  name?: string | null
  avatar?: MediaFragment
  rating: number
  date?: string | Date
  tags?: string[] | null
  text?: string | null
}>()

const { locale } = useI18n()

registerTimeAgoLocale('cs', csTimeAgo)

const when = computed(() => {
  if (!props.date) return ''
  const date = typeof props.date === 'object' ? props.date : new Date(props.date)
  return format(date, locale.value)
})

</script>

<style lang="scss">
.testimonial {
  display: flex;
  flex-direction: column;
  padding: _rem(32px);
  border-radius: _rem(16px);
  border: 1px solid var(--color-dark-16-lines);
  gap: _rem(16px);

  @include breakpoint(small down) {
    padding: _rem(24px);
  }

  &__person {
    display: flex;
    align-items: center;
    gap: _rem(8px);
  }

  &__avatar {
    width: _rem(56px);
    height: _rem(56px);
    border-radius: 99px;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
  }

  &__name {
    font-weight: 500;
    font-size: _rem(18px);
    width: 100%;
  }

  &__rating {
    color: var(--color-gold);
    margin-right: _rem(8px);
  }

  &__when {
    font-size: _rem(14px);
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: _rem(4px);
  }

  &__text {
    font-size: _rem(14px);
  }
}
</style>