<template>
  <div class="acronym">
    <div v-for="item in props.letters">
      <div class="acronym__letter">{{ item.letter }}</div>
      <div class="acronym__caption">{{ item.title }}</div>
      <p class="acronym__explanation">{{ item.sub_text }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  letters: {
    letter: string
    title: string
    sub_text: string
  }[]
}>()
</script>

<style lang="scss">
.acronym {
  display: flex;
  gap: _rem(24px);
  padding: _rem(32px) 0;
  overflow: hidden;
  
  @include breakpoint(small down) {
    flex-direction: column;
    gap: _rem(16px);
    text-align: center;
    align-items: center;
  }

  > div { flex: 1 0 }

  &__letter {
    @include heading-font;
    font-size: _rem(48px);
    margin-bottom: _rem(16px);
    color: var(--color-sand);

    @include breakpoint(small down) {
      font-size: _rem(68px);
      margin-bottom: 0;
    }
  }

  &__caption {
    font-weight: 500;
    font-size: _rem(18px);
  }
}
</style>