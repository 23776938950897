<template>
  <div class="audio-widget" v-intersect>
    <div class="audio-widget__icon">
      <div ref="progressEl" class="audio-widget__progress" />
      <Icon :name="'flag-' + props.language" size="large" />
    </div>
    <div class="audio-widget__text">
      <a :href="props.file" target="_blank"><h4>{{ props.title }}</h4></a>
      <span aria-hidden="true">{{ humanizeFileSize(props.fileSize) }}</span>
    </div>
    <div class="audio-widget__btn">
      <button
        :disabled="!canPlay"
        :aria-label="isPlaying ? $t('media.pause') : $t('media.play')"
        @click="isPlaying ? pause() : play()"
      >
        <Icon :name="isPlaying ? 'pause-fill' : 'play-fill'" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { humanizeFileSize } from '~/lib/helpers'

const { hook, callHook } = useNuxtApp()

const props = defineProps<{
  file: string
  title: string
  fileSize?: string
  language?: string
}>()

const canPlay = ref(false)
const isPlaying = ref(false)
const audio = ref<HTMLAudioElement | null>(null)
const progressEl = ref<HTMLElement | null>(null)

const componentId = getCurrentInstance()?.uid

onMounted(() => {
  prepareAudio()
  hook('audio:play' as any, (id) => id !== componentId && pause())
})

onUnmounted(() => {
  audio.value = null
})

const prepareAudio = async () => {
  audio.value = new Audio()
  audio.value.preload = 'metadata'
  audio.value.src = props.file

  audio.value.addEventListener('canplay', () => canPlay.value = true)
  audio.value.addEventListener('timeupdate', (e: any) => updateProgress(e.target?.currentTime))
  audio.value.addEventListener('ended', onEnded)
}

const play = () => {
  if (isPlaying.value || !audio.value) return

  callHook('audio:play' as any, componentId)
  audio.value.play()
  isPlaying.value = true
}

const pause = () => {
  if (!isPlaying.value || !audio.value) return

  audio.value.pause()
  isPlaying.value = false
}

const updateProgress = (time) => {
  if (!progressEl.value || !audio.value) return

  const progress = Math.round(time / audio.value.duration * 100)
  progressEl.value.style.backgroundImage = `conic-gradient(transparent ${progress}%, 0, var(--color-dark-8-bg) ${100 - progress}%)`
}

const onEnded = () => {
  pause()
  audio.value && (audio.value.currentTime = 0)
}
</script>

<style lang="scss">
.audio-widget {
  display: flex;
  align-items: center;
  gap: _rem(16px);
  padding: _rem(16px);
  border: 1px solid var(--color-dark-16-lines);
  border-radius: var(--box-radius-large);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: _rem(56px);
    height: _rem(56px);
    border-radius: 999px;
    position: relative;
  }

  &__progress {
    @include absolute-cover;
    border-radius: 999px;
    background-image: conic-gradient(transparent 0%, 0, var(--color-dark-8-bg) 100%);
    transform: scale(0.5);
    z-index: -1;
  }
    &[data-animated] &__progress {
      transform: none;
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.180, 1.5) 500ms;
    }

  &__text {
    flex: 1;
    display: flex;
    align-items: center;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    h4 {
      font-weight: 500;
      font-size: _rem(18px);
    }

    span {
      font-size: _rem(14px);
      color: var(--color-dark-50);
      padding-left: _rem(16px);
      white-space: nowrap;
    }
  }

  &__btn button {
    display: flex;
  }
}
</style>