<template>
  <div :class="classes">
    <slot />
    <div v-for="i in cols - 1" class="flexgrid__dummy" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  cols: number
  smCols?: number
  mdCols?: number
  spacing?: 'small' | 'medium' | 'large'
  align?: 'start' | 'center' | 'end'
  nowrap?: string
  pageWidth?: boolean
}>()

const classes = computed(() => [
  'flexgrid',
  props.cols ? `flexgrid--cols-${props.cols}` : '',
  props.mdCols ? `flexgrid--mdcols-${props.mdCols}` : '',
  props.smCols ? `flexgrid--smcols-${props.smCols}` : '',
  props.spacing ? `flexgrid--spacing-${props.spacing}` : 'medium',
  props.align ? `flexgrid--align-${props.align}` : '',
  props.nowrap ? `flexgrid--nowrap` : '',
  props.pageWidth ? `flexgrid--pagewidth` : ''
])
</script>

<style lang="scss">
.flexgrid {
  $max-cols: 6;

  --gap: var(--grid-gap);
  &--spacing-small { --gap: calc(var(--grid-gap) * 2 / 3) } // 16
  &--spacing-large { --gap: calc(var(--grid-gap) / 0.75) } // 32

  display: flex;
  justify-content: space-between;
  gap: var(--gap);

  &:not(&--nowrap) {
    flex-wrap: wrap;
  }

  &--pagewidth {
    max-width: calc(var(--page-width) + var(--page-margin) * 2);
    padding: 0 var(--page-margin);
    margin-left: auto;
    margin-right: auto;
  }

  &--align-start { align-items: flex-start }
  &--align-end { align-items: flex-end }
  &--align-center { align-items: center }

  > * {
    flex: 1 0;
  }

  @for $i from 1 through $max-cols {
    &--cols-#{$i} > * {
      flex-basis: calc(math.div(100, $i) * 1% - var(--gap));
    }
  }
  @for $i from 1 through $max-cols {
    @include breakpoint(medium down) {
      &--mdcols-#{$i} > * {
        flex-basis: calc(math.div(100, $i) * 1% - var(--gap));
      }
    }
  }
  @for $i from 1 through $max-cols {
    @include breakpoint(small down) {
      &--smcols-#{$i} > * {
        flex-basis: calc(math.div(100, $i) * 1% - var(--gap));
      }
    }
  }

  &__dummy {
    height: 0;
    line-height: 0;
    visibility: hidden;
  }
}
</style>