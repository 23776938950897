<template>
  <div class="opening-block">
    <div class="opening-block__col">
      <h4>{{ props.locationName }}</h4>
      <ul>
        <li v-for="it in (mainOpeningHours.groups as any[])">
          {{ it.days.join('-') }}: <span class="time">{{ it.times || $t('location.closed') }}</span>
        </li>
      </ul>
    </div>
    <div v-for="col in props.columns" class="opening-block__col">
      <h4>{{ col.column_title }}</h4>
      <div v-html="col.column_content" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { OpeningHoursFragment } from '#gql'
import { parseOpeningHours } from '~/lib/helpers'

const props = defineProps<{
  openingHours?: OpeningHoursFragment[] | null
  locationName?: string | null
  columns?: { column_title: string, column_content: string }[]
}>()

const mainOpeningHours = ref(parseOpeningHours(props.openingHours || []))

watch(() => props.openingHours, () => {
  mainOpeningHours.value = parseOpeningHours(props.openingHours || [])
})
</script>

<style lang="scss">
.opening-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: _rem(64px);
  row-gap: _rem(32px);

  @include breakpoint(medium down) {
    grid-template-columns: 1fr 1fr;
    column-gap: _rem(32px);
  }
  @include breakpoint(small down) {
    grid-template-columns: 1fr;
  }

  &__col {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    h4 {
      font-weight: 500;
      font-size: _rem(18px);
      margin-bottom: _rem(16px);
    }
  }
}
</style>