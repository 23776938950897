<template>
  <div ref="container" class="video" :class="{ disabled: props.disableControls }">
    <video :src="props.url + '#t=0.1'" preload="metadata" playsinline disablepictureinpicture />
    <div class="video__controls">
      <button class="video__play">
        <Icon name="play" />
      </button>
      <div class="video__progress">
        <div class="video__progress-line" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VideoPlayer from '~/lib/video-player'

const props = defineProps<{
  url: string
  disableControls?: boolean
}>()

const container = ref<HTMLElement>()
let videoPlayer: VideoPlayer | null = null

onMounted(() => {
  container.value && (videoPlayer = new VideoPlayer(container.value, true))
})

onBeforeUnmount(() => {
  videoPlayer?.deactivate()
})

</script>

<style lang="scss">
.video {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  user-select: none;

  &--playing {
    cursor: pointer;
  }

  &--fade-controls {
    cursor: none;
  }

  &.disabled {
    pointer-events: none;
  }

  video {
    width: 100%;
    height: auto;
  }

  figcaption {
    display: none;
  }

  &__controls {
    @include absolute-cover;

    &[disabled] {
      pointer-events: none;
    }
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: _rem(56px);
    height: _rem(56px);
    border-radius: 999px;
    background-color: var(--color-white);
    transform: translate(-50%, -50%);
    transition: 150ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
    &--playing &__play {
      opacity: 0;
    }

  &__progress {
    width: 80%;
    height: 28px;
    left: 50%;
    bottom: _rem(40px);
    position: absolute;
    display: flex;
    align-items: center;
    transform: translateX(-50%);
    transition: opacity 200ms ease;
    cursor: pointer;

    @include breakpoint(small down) {
      bottom: _rem(14px);
    }
    
    &-line {
      width: 100%;
      height: 8px;
      border-radius: 999px;
      background-color: #ffffff3c;
      background-image: linear-gradient(90deg, white 0%, white 100%);
      background-repeat: no-repeat;
      background-size: 0%;
    }
  }
    &:not(.video--initialized) &__progress,
    &--fade-controls &__progress {
      opacity: 0;
    }
}
</style>