import type { MediaFragment } from '#gql'
import { useModal } from 'vue-final-modal'

import Modal from '../components/modals/Modal.vue'
import GalleryModal from '../components/modals/GalleryModal.vue'
import JobModal from '../components/modals/JobModal.vue'
import TextAndEmbedModal from '../components/modals/TextAndEmbedModal.vue'
import TextAndImageModal from '../components/modals/TextAndImageModal.vue'

export const openModal = (title: string | null , content: string) => {
  const modal = useModal({
    component: Modal,
    attrs: { title: title || '', rawContent: content}
  })
  modal.open()
}

export const openIframeModal = (url: string) => {
  const modal = useModal({
    component: Modal,
    attrs: {
      size: 'page',
      rawContent: `<iframe src="${url}" frameborder="0" style="width: 100%; height: 100%;"></iframe>`
    }
  })
  modal.open()
}

export const openGalleryModal = (images: MediaFragment[] | null, initialIndex?: number) => {
  if (!images?.length) return
  const modal = useModal({ component: GalleryModal, attrs: { images, initialIndex } })
  modal.open()
}

export const openJobModal = (jobTitle, form) => {
  const modal = useModal({ component: JobModal, attrs: { jobTitle, form } })
  modal.open()
}

export const openTextAndEmbedModal = (title, content, embedUrl) => {
  const modal = useModal({ component: TextAndEmbedModal, attrs: { title, content, embedUrl } })
  modal.open()
}

export const openTextAndImageModal = (title, content, image) => {
  const modal = useModal({ component: TextAndImageModal, attrs: { title, content, image } })
  modal.open()
}
