<template>
  <div class="gallery-slider" @click="onRootClick">
    <div ref="imagesContainer" class="gallery-slider__images">
      <div v-for="item in props.images" class="gallery-slider__image">
        <VideoPlayer v-if="isVideo(item)" :url="item.original_url || ''" />
        <Image :media="item" size="lg" tabindex="-1" @click="onImageClick" />
      </div>
    </div>

    <div class="gallery-slider__nav">
      <div>
        <button class="btn btn--circle btn--arrow-right" @click="prev" :disabled="currentImage === 0">
          <Icon name="arrow-left" size="small" />
        </button>
        <button class="btn btn--circle btn--arrow-right" @click="next" :disabled="currentImage === props.images.length - 1">
          <Icon name="arrow-right" size="small" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MediaFragment } from '#gql'

const props = defineProps<{
  images: MediaFragment[]
  initialIndex?: number
  onEmptyClick?: () => void
}>()

let imagesObserver: IntersectionObserver

const currentImage = ref<number>(props.initialIndex || 0)
const imagesContainer = ref<HTMLElement | null>(null)

const isVideo = (media: MediaFragment) => media.mime_type?.startsWith('video/')

const setupObserver = () => {
  imagesObserver = new IntersectionObserver( entries => {
    const visibleImage = entries.find(entry => entry.isIntersecting)

    if (visibleImage !== undefined) {
      currentImage.value = Array.from(imagesContainer.value?.children || []).indexOf(visibleImage.target)
    }
  }, { threshold: 0.9 })
}

const scrollTo = (index: number) => {
  const targetImage = imagesContainer.value?.children[index]
  targetImage?.scrollIntoView({ inline: 'center', behavior: 'smooth' })
}

const onImageClick = (e) => e.target.scrollIntoView({ inline: 'center', behavior: 'smooth' })

const onRootClick = (e) => {
  [ 'gallery-slider', 'gallery-slider__image', 'gallery-slider__images', 'gallery-slider__nav' ]
    .includes(e.target.className) && props.onEmptyClick?.()
}

const prev = () => scrollTo(currentImage.value - 1)
const next = () => scrollTo(currentImage.value + 1)

onMounted(() => {
  setupObserver()
  Array.from(imagesContainer.value?.children || []).forEach(slide => imagesObserver.observe(slide))
  scrollTo(props.initialIndex || 0)
  imagesContainer.value?.focus()
})

onUnmounted(() => imagesObserver?.disconnect())
</script>

<style lang="scss">
.gallery-slider {
  display: flex;
  overflow: hidden;
  height: 100%;
  container-type: size;

  &__images {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    scroll-behavior: smooth;
    // padding-left: 10%;
    // padding-right: 10%;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: center;
    height: 85cqh;

    &:nth-child(odd) {
      flex: 1 0 calc(100% - 200px);
    }
    &:nth-child(even) {
      // margin-left: 5%;
    }

    &:first-child { margin-left: calc(50% - 100px); }
    &:last-child:nth-child(odd) { margin-right: calc(50% - 100px); }
    &:last-child:nth-child(even) { margin-right: 50%; }

    picture {
      height: 100%;
    }

    img, .video {
      height: 100%;
      width: auto;
      max-width: calc(100vw - 400px);
      object-fit: contain;
    }

    .video {
      height: fit-content;
    }

    @include breakpoint(small down) {
      &:nth-child(odd) {
        flex: 1 0 calc(100% - 80px);
      }

      img, .video {
        max-width: calc(100vw - 100px);
      }
    }
  }

  &__nav {
    left: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 1;

    > div {
      display: flex;
      gap: _rem(16px);
    }
  }
}
</style>
