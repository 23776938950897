<template>
  <div class="text-n-boxes">
    <div class="text-n-boxes__content">
      <h2 v-if="props.title">{{ props.title }}</h2>
      <RichText v-if="props.text" :text="props.text" />
    </div>
    <div class="text-n-boxes__boxes">
      <a v-for="link in props.links"
        :href="link.link || undefined"
        target="_blank"
        :class="`text-n-boxes__box ${link.color?.toLowerCase() || 'red'}`"
        @click="(e) => onBoxClick(e, link)"
      >
        <h3>{{ link.title }}</h3>
        <Icon name="arrow-forward" />
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { LinkItemsFragment } from '#gql'
import { openTextAndEmbedModal } from '~/lib/modals'

const props = defineProps<{
  title?: string | null
  text?: string | null
  flip?: boolean
  links: LinkItemsFragment[]
}>()

const onBoxClick = (e, link: LinkItemsFragment) => {
  if (!link.link && link.modal_content) {
    e.preventDefault()
    openTextAndEmbedModal(link.title, link.modal_content, link.modal_iframe)
  }
}
</script>

<style lang="scss">
.text-n-boxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: var(--grid-double-gap);

  @include breakpoint(small down) {
    grid-template-columns: 1fr;
    gap: _rem(24px);
  }

  &__content {
    > * { max-width: _rem(496px); }
  }

  &__boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--grid-gap);
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: var(--box-radius-large);
    padding: _rem(28px);
    aspect-ratio: 1;
    color: white;
    cursor: pointer;

    &.red { background-color: var(--color-red); }
    &.blue { background-color: var(--color-blue); }
    &.gold { background-color: var(--color-gold); color: var(--color-dark); }
    &.grey { background-color: var(--color-dark-8-bg); color: var(--color-dark); }

    @include breakpoint(medium down) {
      padding: _rem(24px);
    }
    @include breakpoint(small down) {
      padding: _rem(20px);
    }

    h3 {
      font-weight: 300;
      @include breakpoint(medium down) {
        font-size: _rem(20px);
      }
    }

    .icon {
      transition: transform 300ms ease;
    }
      &:hover .icon {
        transform: translateX(50%);
      }
  }
}
</style>