<template>
  <Modal :title="props.title">
    <FlexGrid :cols="props.image ? 2 : 1" :sm-cols="1" spacing="large">
      <div>
        <Image v-if="props.image" class="w-100" :media="props.image" size="md" />
      </div>
      <div v-html="props.content" />
    </FlexGrid>
  </Modal>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'

const props = defineProps<{
  title?: string
  content?: string
  image?: MediaFragment | null
}>()
</script>