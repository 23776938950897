/**
 * Video player control class
 */
export default class VideoPlayer {
  container: HTMLElement | Element
  video: HTMLVideoElement | null
  progressBar: HTMLElement | null
  progressBarLine: HTMLElement | null
  playBtn: HTMLElement | null
  playOnClick: boolean
  fullscreenBreakpoint: number
  cursorHidingTimeout: number | null
  scrubbing: boolean
  playing: boolean

  constructor(container: HTMLElement | Element, playOnClick = false, fullscreenBreakpoint = 768) {
    this.container = container
    this.video = this.container.querySelector('video')
    this.progressBar = this.container.querySelector('.video__progress')
    this.progressBarLine = this.progressBar?.children[0] as HTMLElement
    this.playBtn = this.container.querySelector('.video__play')
    this.playOnClick = playOnClick
    this.fullscreenBreakpoint = fullscreenBreakpoint
    this.cursorHidingTimeout = null
    this.scrubbing = false
    this.playing = false

    this._setupHandlers()
  }

  _setupHandlers() {
    if (!this.video || !this.playBtn) {
      console.error('Failed to setup video player, required elements not found.')
      return
    }

    // Play / pause
    this.playBtn.addEventListener('click', this._onPlayClick.bind(this), { passive: true })
    this.container.addEventListener('click', this._onContainerClick.bind(this), { passive: true })

    // Double-click to fullscreen
    this.container.addEventListener('dblclick', () => this.video?.requestFullscreen(), { passive: true })
    this.container.addEventListener('taphold', () => this.video?.requestFullscreen(), { passive: true })

    // Video playback bindings
    this.video.addEventListener('timeupdate', this._onTimeUpdate.bind(this), { passive: true })
    this.video.addEventListener('ended', this._onVideoEnded.bind(this), { passive: true })
    this.video.addEventListener('play', this._onPlay.bind(this), { passive: true })
    this.video.addEventListener('pause', this._onPause.bind(this), { passive: true })

    // Handle scrubbing
    if (this.progressBar) {
      this.progressBar.addEventListener('pointerdown', this._onProgressBarMouseDown.bind(this), { passive: true })
      this.progressBar.addEventListener('pointerup', this._onProgressBarMouseUp.bind(this), { passive: true })
      this.progressBar.addEventListener('pointermove', this._onProgressBarMove.bind(this), { passive: true })
      this.progressBar.addEventListener('click', this._onProgressBarClick.bind(this), { passive: true })
    }

    // Auto hide cursor and controls when playing
    this.container.addEventListener('mousemove', this._onContainerMouseMove.bind(this), { passive: true })
  }

  _onPlayClick(e: MouseEvent) {
    e.stopPropagation()
    this.playing ? this.pause() : this.play()
    if (!this.playing && window.innerWidth < this.fullscreenBreakpoint) {
      this.video?.requestFullscreen()
    }
  }
  _onContainerClick() {
    if (this.playing) this.pause()
    else if (this.playOnClick) this.play()
  }
  _onTimeUpdate() {
    if (this.video && this.progressBarLine) {
      const videoProgress = this.video.currentTime / this.video.duration * 100
      this.progressBarLine.style.backgroundSize = `${ videoProgress }% 100%`
    }
  }
  _onVideoEnded() {
    this.pause()
    this.video && (this.video.currentTime = 0)
  }

  _onProgressBarMouseDown() {
    this.scrubbing = true
  }

  _onProgressBarMouseUp() {
    this.scrubbing = false
  }

  _onProgressBarClick(e: MouseEvent) {
    e.stopPropagation()
    this.scrub( (this.video?.duration || 0) * e.offsetX / (this.progressBar?.offsetWidth || 1) )
  }

  _onProgressBarMove(e: PointerEvent) {
    if (this.scrubbing) this.scrub( (this.video?.duration || 0) * e.offsetX / (this.progressBar?.offsetWidth || 1) )
  }
  _onContainerMouseMove() {
    if (this.cursorHidingTimeout) clearTimeout(this.cursorHidingTimeout)
    this.container.classList.remove('video--fade-controls')

    this.cursorHidingTimeout = window.setTimeout(() => {
      this.container.classList.add('video--fade-controls')
      this.cursorHidingTimeout = null
    }, 1500)
  }

  _onPlay() {
    this.container.classList.add('video--initialized')
    this.container.classList.add('video--playing')
    this.playing = true
  }

  _onPause() {
    this.container.classList.remove('video--playing')
    this.playing = false
  }

  play() {
    if (!this.video) return
    this.video.play()
  }

  pause() {
    if (!this.video) return
    this.video.pause()
  }

  scrub(time: number) {
    if (!this.video || !this.progressBarLine) return
    const progressPercentage = (time / this.video.duration) * 100
    this.progressBarLine.style.backgroundSize = `${progressPercentage}%`
    this.video.currentTime = time
  }

  deactivate() {
    if (!this.video || !this.progressBar || !this.playBtn) return
    this.pause()

    this.playBtn.removeEventListener('click', this._onPlayClick)
    this.container.removeEventListener('click', this._onContainerClick)
    this.video.removeEventListener('timeupdate', this._onTimeUpdate)
    this.video.removeEventListener('ended', this._onVideoEnded)
    this.progressBar.removeEventListener('pointerdown', this._onProgressBarMouseDown)
    this.progressBar.removeEventListener('pointerup', this._onProgressBarMouseUp)
    this.progressBar.removeEventListener('pointermove', this._onProgressBarMove)
    this.progressBar.removeEventListener('click', this._onProgressBarClick)
    this.container.removeEventListener('mousemove', this._onContainerMouseMove)
  }
}
