<template>
  <div>
    <PageContentFilter v-if="props.showFilter && filters.length"
      :title="$t('filter.filter_action')"
      :data="shuffledItems"
      :filters="filters"
      sticky
      @filter="onFilterChange"
    />
    <Section :background="props.background">
      <Masonry :items="filtered" />
      <div v-if="props.moreLabel && ((props.threshold || 0) < props.items.length)" class="text-center">
        <Spacer size="large" />
        <CTA :text="props.moreLabel" :link="props.moreLink" outline />
      </div>
    </Section>
  </div>
</template>

<script lang="ts" setup>
import type { FilterDefinition } from '~/components/PageContentFilter.vue'

const props = defineProps<{
  items: any[]
  modelName?: string | null
  moreLabel?: string | null
  moreLink?: string | null
  threshold?: number | null
  background?: string | null
  anchor?: string | null
  showFilter?: boolean | null
  title?: string | null // unused
}>()

const { t } = useI18n()

const modelNames = computed(() => props.modelName?.split(','))

const filters = computed((): FilterDefinition[] => {
    if (modelNames.value?.includes('CollectionItem'))
      return [
        { type: 'select', name: 'type', label: t('filter.show_all'), filterBy: 'type', labelBy: 'type' }
      ]
    else if (modelNames.value?.includes('ProgramAndActivity'))
      return [
        { type: 'select', name: 'location', label: t('filter.all_locations'), filterBy: 'location', labelBy: 'location' },
        { type: 'select', name: 'type', label: t('filter.all_activities'), filterBy: 'type', labelBy: 'type' }
      ]
    else if (modelNames.value?.includes('Event'))
      return [
        { type: 'select', name: 'location', label: t('filter.all_locations'), filterBy: 'location', labelBy: 'location' },
      ]
    else return []
})

const shuffledItems = computed( () => props.items.sort(() => Math.random() - 0.5) )

const filtered = ref<any[]>(shuffledItems.value.slice(0, props.threshold || shuffledItems.value.length) || [])
const onFilterChange = (data) => filtered.value = data.slice(0, props.threshold || data.length)
</script>