<template>
  <div class="timeline">
    <h2 class="timeline__title">{{ props.title }}</h2>
    <div class="timeline__subtitle" v-html="props.subtitle" />

    <div class="timeline__body">
      <div class="timeline__body-left" v-if="!isMobile">
        <div v-for="item in itemsLeft"
          class="timeline__card"
          :class="{ 'timeline__card--with-image': !!item.image }"
          :style="{ backgroundImage: item.image ? `var(--img-overlay), url(${item.image.original_url})` : '' }"
          data-animation="slide-fade-in"
          v-intersect
        >
          <div class="timeline__card-content">
            <h3>
              <span v-if="item.year" class="timeline__card-year">{{ item.year }}&nbsp;</span>
              {{ item.title }}
            </h3>
            <div v-if="item.description"
              class="timeline__card-description"
              v-html="item.description"
              v-check-overflow
            />
            <a href="" class="underlined-link" @click.prevent="() => openDetail(item)">{{ $t('content.more') }}</a>
            <nuxt-link v-if="item.cta && item.ctaLink" :to="path(item.ctaLink)">{{ item.cta }}</nuxt-link>
          </div>
        </div>
      </div>

      <div class="timeline__body-right" :class="'offset-' + rightColOffset">
        <div class="timeline__card timeline__card--dummy" />
        <div v-for="item in itemsRight"
          class="timeline__card"
          :class="{ 'timeline__card--with-image': !!item.image }"
          :style="{ backgroundImage: item.image ? `var(--img-overlay), url(${item.image.original_url})` : '' }"
          data-animation="slide-fade-in"
          v-intersect
        >
          <div class="timeline__card-content">
            <h3>
              <span v-if="item.year" class="timeline__card-year">{{ item.year }}&nbsp;</span>
              {{ item.title }}
            </h3>
            <div v-if="item.description"
              class="timeline__card-description"
              v-html="item.description"
              v-check-overflow
            />
            <a href="" class="underlined-link" @click.prevent="() => openDetail(item)">{{ $t('content.more') }}</a>
            <nuxt-link v-if="item.cta && item.ctaLink" :to="path(item.ctaLink)">{{ item.cta }}</nuxt-link>
          </div>
        </div>
      </div>
    </div>

    <div class="timeline__footer">
      <nuxt-link v-if="props.cta && props.ctaLink" :to="path(props.ctaLink)" class="btn btn--red">{{ props.cta }}</nuxt-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'
import { path } from '~/lib/helpers'
import { openModal } from '~/lib/modals'

export interface TimelineItem {
  title: string | null
  description?: string | null
  year?: string | null
  cta?: string | null
  ctaLink?: string | null
  image?: MediaFragment | null
}

const props = defineProps<{
  title?: string | null
  subtitle?: string | null
  cta?: string | null
  ctaLink?: string | null
  items: TimelineItem[]
}>()

const isMobile = ref(false)

const itemsLeft = computed( () => props.items.filter((_, i) => i % 2 === 0) )
const itemsRight = computed( () => isMobile.value ? props.items : props.items.filter((_, i) => i % 2 !== 0) )
const rightColOffset = computed( () => props.items.length && props.items[0].image ? 'big' : 'small' )

const updateWindowSize = () => { isMobile.value = window.innerWidth < 768 }

onMounted(() => {
  updateWindowSize()
  window.addEventListener('scroll', updateWindowSize)
})

onUnmounted(() => window.removeEventListener('scroll', updateWindowSize))

const openDetail = (item: TimelineItem) => {
  openModal(item.title || '', item.description || '')
}
</script>

<style lang="scss">
.timeline {
  --card-h-spacing: calc(#{_rem(64px)} + #{_rem(12px)});
  --card-v-spacing: #{_rem(96px)};
  @include breakpoint(small down) {
    --card-h-spacing: #{_rem(42px)};
    --card-v-spacing: #{_rem(48px)};
  }
  
  --img-overlay: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  --background: var(--color-white);
  .section--white & { --background: var(--color-dark-8-bg); }

  &__title, &__subtitle {
    text-align: center;
  }

  &__subtitle {
    max-width: _rem(480px);
    margin: 0 auto;
  }
    &__subtitle + &__body {
      margin-top: _rem(32px);
    }

  &__body {
    display: flex;

    &-left, &-right {
      flex: 1 0 50%;
      padding-top: _rem(58px);
    }
    &-left {
      border-right: 1px solid var(--color-dark-16-lines);
    }
    &-right {
      border-left: 1px solid var(--color-dark-16-lines);
      @include breakpoint(small down) {
        border-width: 2px;
      }
    }
  }

  &__footer {
    text-align: center;
    margin-top: _rem(32px);
  }

  &__card {
    display: flex;
    background-color: var(--background);
    border-radius: _rem(16px);
    margin-bottom: var(--card-v-spacing);
    margin-right: var(--card-h-spacing);
    color: var(--color-dark);

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      padding: _rem(96px) _rem(32px) _rem(32px);

      @include breakpoint(medium down) {
        padding: _rem(96px) _rem(24px) _rem(24px);
      }
    }

    &-description {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      @include with-more-text;
    }

    &:before {
      content: '';
      padding-bottom: 67%;
      display: inline-block;
    }

    &--with-image {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: _rem(8px);
      color: var(--color-white);

      &:before {
        padding-bottom: 105%;
      }
    }

    &--dummy {
      visibility: hidden;
      margin-bottom: 0;

      @include breakpoint(small down) {
        display: none;
      }

      .offset-small > &::before {
        padding-bottom: 43%;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: _rem(24px);
      height: _rem(24px);
      left: calc(var(--card-h-spacing) * -1 - _rem(13px));
      top: calc(50% - _rem(12px));
      border-radius: 99px;
      background-color: var(--color-dark);
      background-image: radial-gradient(circle, var(--color-white) 0%, var(--color-white) 24%, var(--color-dark) 27%, var(--color-dark) 100%);
      transition: opacity 150ms linear 500ms, transform 400ms cubic-bezier(0.175, 0.885, 0.180, 2) 550ms;
      transform: scale(0.7);
      opacity: 0;
    }
      &[data-animated]::after {
        transform: none;
        opacity: 1;
      }

    &-year {
      position: absolute;
      top: _rem(32px);
      left: _rem(32px);
      font-size: _rem(64px);
      line-height: 0.9;
      @include heading-font;

      @include breakpoint(medium down) {
        font-size: _rem(48px);
        left: _rem(24px);
      }
    }

    p {
      font-size: _rem(14px);
      z-index: 1;
    }
  }
    &__body-right &__card {
      margin-right: 0;
      margin-left: var(--card-h-spacing);
    }
    &__body-left &__card {
      &::after {
        right: calc(var(--card-h-spacing) * -1 - _rem(12.5px));
        left: auto;
      }
    }
}
</style>