<template>
  <div :class="classes">
    <h2 v-if="slots.title && !props.image">
      <slot name="title" />
    </h2>
    <div class="splitcontent__cols">
      <div class="splitcontent__left">
        <div v-if="slots['text-left'] && !props.image">
          <RichText><slot name="text-left" /></RichText>
        </div>
        <Image v-if="props.image" class="splitcontent__image" :media="props.image" size="md" />
      </div>
      <div class="splitcontent__right">
        <template v-if="slots['text-right']">
          <RichText><slot name="text-right" /></RichText>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'

const props = defineProps<{
  image?: MediaFragment
  flip?: boolean
}>()

const slots = useSlots()

const classes = computed(() => [
  'splitcontent',
  props.flip ? 'splitcontent--flip' : '',
  props.image ? 'splitcontent--with-image' : ''
])

</script>

<style lang="scss">
.splitcontent {
  &__cols {
    display: flex;
    gap: var(--grid-double-gap);
  
    @include breakpoint(small down) {
      flex-wrap: wrap;
    }
  }
    &--flip &__cols {
      @include breakpoint(small up) {
        flex-direction: row-reverse;
      }
    }
    &--with-image &__cols {
      align-items: center;

      .richtext {
        @include breakpoint(medium up) {
          max-width: _rem(496px);
          margin-top: _rem(-12px);
        }
      }
    }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 calc(50% - var(--grid-double-gap) / 2);

    @include breakpoint(small down) {
      flex: 1 0 100%;
    }

    > div {
      max-width: _rem(496px);
    }
  }

  &__right {
    flex: 1 0 calc(50% - var(--grid-double-gap) / 2);
    display: flex;
    justify-content: end;

    @include breakpoint(small down) {
      flex: 1 0 100%;
      display: block;
      margin-top: _rem(16px);
    }

    > div {
      width: 100%;
      max-width: _rem(496px);
    }
  }

  &__image {
    width: 100%;
    height: auto;
    border-radius: var(--box-radius);
  }

  h2 {
    margin-bottom: _rem(24px);
  }
}
</style>