<template>
  <div class="stepslider" v-intersect>
    <div ref="header" class="stepslider__header">
      <h2 class="stepslider__title">{{ props.title }}</h2>
      <div v-if="currentStep > 0 || isNextEnabled" class="stepslider__arrows">
        <button class="btn btn--outline btn--circle btn--arrow-left" :disabled="currentStep === 0" @click="prevStep">
          <Icon name="arrow-left" size="small" />
        </button>
        <button class="btn btn--outline btn--circle btn--arrow-right" :disabled="!isNextEnabled" @click="nextStep">
          <Icon name="arrow-right" size="small" />
        </button>
      </div>
    </div>

    <div
      ref="itemsWrapper"
      class="stepslider__items"
      role="list"
    >
      <div
        v-for="(step, i) in props.steps"
        class="stepslider__item"
        :class="{ inactive: i < currentStep }"
        role="listitem"
      >
        <div class="stepslider__item-number">{{ step.step || i + 1 }}</div>
        <div class="stepslider__item-text">
          <h4 class="stepslider__item-title">{{ step.title }}</h4>
          <p class="stepslider__item-description">{{ step.description }}</p>
        </div>
      </div>
    </div>

    <div class="stepslider__footer" v-if="props.cta && props.ctaLink">
      <CTA :text="props.cta" :link="path(props.ctaLink)" color="gold" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSwipe } from '@vueuse/core'
import { path } from '~/lib/helpers'

const props = defineProps<{
  title: string
  cta?: string
  ctaLink?: string
  steps: {
    step?: number | string
    title: string
    description?: string
  }[]
}>()

const header = ref<HTMLElement | null>(null)
const itemsWrapper = ref<HTMLElement | null>(null)
const isNextEnabled = ref<boolean>(true)
const isSwiping = ref<boolean>(false)
const currentStep = ref<number>(0)

onMounted(() => {
  update()
  window.addEventListener('resize', update)
})

const { direction } = useSwipe(itemsWrapper, {
  onSwipe() {
    if (isSwiping.value) return // one step per swipe
    isSwiping.value = true

    if (direction.value === 'left') nextStep()
    else if (direction.value === 'right') prevStep()
  },
  onSwipeEnd() { isSwiping.value = false }
})

const update = () => {
  const lastItem = itemsWrapper.value?.lastElementChild
  isNextEnabled.value = (header.value?.getBoundingClientRect()?.right || 0) < (lastItem?.getBoundingClientRect().right || 0)
}

watch(currentStep, () => {
  const itemWidth = (itemsWrapper.value?.firstElementChild as HTMLElement)?.offsetWidth || 0
  const gap = parseInt(getComputedStyle(itemsWrapper.value as HTMLElement).gap)
  
  const items = [...itemsWrapper.value?.children as HTMLCollection]
  const offsetBy = (itemWidth + gap) * currentStep.value

  items.forEach((it, i) => {
    if (i >= currentStep.value) {
      (it as HTMLElement).style.transform = `translate3d(${offsetBy * -1}px, 0, 0)`
    }
  })

  setTimeout(() => update(), 400)
})

const prevStep = () => (currentStep.value > 0) && currentStep.value--
const nextStep = () => (currentStep.value < props.steps.length - 1) && currentStep.value++
</script>

<style lang="scss">
.stepslider {
  --circle-size: #{_rem(64px)};
  $page-side-space: calc(max( (100vw - var(--page-width)) / 2, var(--page-margin) ));

  &__header {
    @include page-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      margin: 0;
      max-width: 600px;
    }

    .btn {
      @include breakpoint(small down) {
        display: none;
      }
    }
  }

  &__arrows {
    display: flex;
    gap: _rem(4px);
  }

  &__items {
    display: flex;
    overflow: hidden;
    padding-left: $page-side-space;
    gap: _rem(64px);
    margin-top: _rem(64px);
    position: relative;

    background-image: linear-gradient(
      to right,
      transparent,
      transparent calc(var(--circle-size) / 2),
      var(--color-dark-16-lines) calc(var(--circle-size) / 2)
    );
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position-x: max((100vw - var(--page-width)) / 2, var(--page-margin));
    background-position-y: calc(var(--circle-size) / 2);
  }

  &__item {
    min-width: _rem(232px);
    width: _rem(232px);
    transition: all 300ms ease;

    &-number {
      @include heading-font;
      width: var(--circle-size);
      height: var(--circle-size);
      border-radius: 99px;
      font-size: 2rem;
      background-color: var(--color-gold);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-white);
      padding-top: _rem(2px);
      position: relative;
      transition: opacity 100ms linear 150ms, transform 300ms ease;
    }
    &.inactive &-number {
      opacity: 0;
      transform: scale(0);
      transition: opacity 100ms linear, transform 300ms ease;
    }

    &-text {
      transition: opacity 150ms ease 150ms;
    }
    &.inactive &-text {
      opacity: 0;
      transition: opacity 100ms ease;
    }

    &-title {
      font-weight: 500;
      margin: _rem(16px) 0;
    }
  }

  &__footer {
    @include page-content;
    margin-top: _rem(64px);
  }
}
</style>
