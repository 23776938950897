<template>
  <div :class="classes">
    <h2 class="slideshow__title" v-if="props.title && props.slides.length > 1">{{ props.title }}</h2>

    <div class="slideshow__tabs" v-if="props.display === 'tabs' && props.slides.length > 1">
      <button v-for="(slide, i) in props.slides"
        class="btn btn--outline"
        :class="{ faded: currentSlide !== i }"
        @click="currentSlide = i"
      >
        {{ slide.title }}
      </button>
    </div>

    <div class="slideshow__slides">
      <div class="slideshow__slide">
        <div class="slideshow__slide-content">
          <component :is="props.slides.length > 1 ? 'h3' : 'h2'">{{ currentSlideItem.title }}</component>
          <RichText :text="currentSlideItem.description" />

          <CTA v-if="currentSlideItem.cta" v-bind="mapCtaObject(currentSlideItem)" />

          <div v-if="props.display !== 'tabs' && props.slides.length > 1"  class="slideshow__arrows">
            <button
              class="btn btn--outline btn--circle btn--arrow-left"
              :disabled="currentSlide === 0"
              :aria-label="$t('slider.previous')"
              @click="prevSlide"
            >
              <Icon name="arrow-left" size="small" />
            </button>
            <button
              class="btn btn--outline btn--circle btn--arrow-right"
              :disabled="currentSlide === props.slides.length - 1"
              :aria-label="$t('slider.next')"
              @click="nextSlide"
            >
              <Icon name="arrow-right" size="small" />
            </button>
          </div>
        </div>

        <div class="slideshow__slide-images">
          <ImageCompare
            :before="currentSlideItem.images[0]"
            :after="currentSlideItem.images[1]"
          />
          <Note v-if="currentSlideItem.notice_text" :text="currentSlideItem.notice_text || ''" compact />
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { mapCtaObject } from '~/lib/data-mappings'
import type { MediaFragment } from '#gql'

const props = defineProps<{
  title?: string | null
  display?: 'arrows' | 'tabs'
  slides: {
    title: string
    description: string
    notice_text?: string | null
    cta?: string | null
    cta_link?: string | null
    cta_color?: string | null
    images: MediaFragment | MediaFragment[]
  }[]
}>()

const currentSlide = ref(0)
const currentSlideItem = computed(() => props.slides[currentSlide.value])

const nextSlide = () => {
  (currentSlide.value < props.slides.length - 1) && currentSlide.value++
}
const prevSlide = () => {
  (currentSlide.value > 0) && currentSlide.value--
}

const classes = computed(() => [
  'slideshow',
  props.display ? `slideshow--${props.display}` : ''
])
</script>

<style lang="scss">
.slideshow {
  &__tabs {
    display: flex;
    align-items: center;
    gap: _rem(8px);
    padding: _rem(8px) 0 _rem(16px);
    overflow-x: auto;
  }

  &__slides {
    margin-top: _rem(24px);
  }

  &__slide {
    display: flex;
    justify-content: space-between;
    gap: _rem(32px);

    @include breakpoint(medium down) {
      flex-direction: column;
    }

    h3 {
      margin-bottom: _rem(32px);
    }

    &-content {
      flex: 1;
      
      @include breakpoint(large up) {
        max-width: _rem(360px);
      }

      > .btn {
        margin: _rem(32px) 0 0;
      }
    }

    &-images {
      flex: 1;
      
      @include breakpoint(large up) {
        max-width: _rem(685px);
      }
    }
  }

  &__arrows {
    display: flex;
    gap: _rem(4px);
    margin-top: _rem(32px);
  }

  .note {
    margin-top: _rem(16px);
  }
}
</style>