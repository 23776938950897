<template>
  <div class="floorplan">
    <div class="floorplan__floors">
      <div class="floorplan__tabs">
        <button v-for="(floor, i) in props.floors" :class="{ active: selectedFloor === i }" @click="selectedFloor = i">
          {{ floor.title }}
        </button>
      </div>
      <div class="floorplan__plan">
        <Image :media="floor.plan" size="lg" />
      </div>
      <div class="floorplan__plan-actions">
        <a v-if="floor?.virtual_tour_link" class="btn btn--outline" :href="floor?.virtual_tour_link">
          <Icon name="eye" />&nbsp{{ $t('location.virtual_tour') }}
        </a>
        <a v-if="floor?.brochure" class="btn btn--outline" :href="floor?.brochure.original_url" target="_blank">
          <Icon name="download" />&nbsp{{ $t('location.download_brochure') }}
        </a>
      </div>
    </div>

    <div class="floorplan__spaces" v-if="props.spaces.length">
      <Accordion :items="props.spaces.map(it => it.title)">
        <template v-for="(space, i) in props.spaces" v-slot:[`content-${i}`]>
          <template v-for="block in space.dynamic_content">
            <TextAndImages v-if="block.__typename === 'TextAndImagesBlock'" 
              :title="block.title"
              :text="block.body"
              :images="(block.images as MediaFragment[])"
              :layout="block.gallery_layout"
              :flip="!block.images_on_right"
            >
              <template v-if="space.meta?.length" #pretext>
                <div v-for="meta in space.meta">
                  <div class="tag">{{ meta.key }}: {{ meta.value }}</div>
                  <Spacer size="tiny" />
                </div>
              </template>
              <template v-if="block.meta?.length" #widget>
                <FactsList :items="block.meta.map(it => ({ label: it.key, value: it.value }))" />
              </template>
              <template v-if="block.cta" #ctas>
                <CTA :text="block.cta" :link="block.cta_link" :outline="block.cta_outline" :color="block.cta_color" />
              </template>
            </TextAndImages>
          </template>
        </template>
      </Accordion>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'

const props = defineProps<{
  floors: any[]
  spaces: any[]
}>()

const selectedFloor = ref<number>(0)
const floor = computed(() => props.floors[selectedFloor.value])

</script>

<style lang="scss">
.floorplan {
  &__tabs {
    display: flex;

    button {
      flex: 1;
      height: _rem(56px);
      border-bottom: 1px solid var(--color-dark-16-lines);
      @include flex-center;

      &.active {
        border-color: var(--color-dark);
        border-width: 2px;
        font-weight: 500;
      }
    }
  }

  &__plan {
    margin-top: _rem(24px);
    img {
      width: 100%;
      height: auto;
    }
  }

  &__plan-actions {
    display: flex;
    gap: _rem(8px);

    a {
      margin-top: _rem(24px);
    }
  }

  &__spaces {
    margin-top: _rem(56px);
  }
}
</style>