<template>
  <template v-if="props.block.__typename === 'MiniBlockText'">
    <div v-html="props.block.description" />
    <template v-for="text in props.block.contents">
      <div v-html="text.text" />
    </template>
    <Note v-if="props.block.notice" :text="props.block.notice" />
  </template>

  <template v-if="props.block.__typename === 'MiniBlockAudioguide'">
    <FlexGrid :cols="2" :sm-cols="1" spacing="large">
      <div v-html="props.block.content" />
      <FlexGrid :cols="1" spacing="small">
        <AudioWidget v-for="guide in block.guides"
          :title="guide.name"
          :file="guide.file?.original_url"
          :file-size="guide.file?.size"
        />
      </FlexGrid>
    </FlexGrid>
    <Note v-if="props.block.notice" :text="props.block.notice" />
  </template>

  <template v-if="props.block.__typename === 'MiniBlockTextAndImageLink'">
    <FlexGrid :cols="1" spacing="small">
      <TextAndImageWidget v-for="item in props.block.items" v-bind="item" />
    </FlexGrid>
    <Note v-if="props.block.notice" :text="props.block.notice" />
  </template>
</template>

<script lang="ts" setup>
const props = defineProps<{
  block: any
}>()
</script>