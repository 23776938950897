<template>
  <Modal background="transparent" size="full" nopadding>
    <template #default="{ close }">
      <GallerySlider v-if="props.images?.length"
        :images="props.images"
        :initialIndex="props.initialIndex"
        @empty-click="close"
      />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'

const props = defineProps<{
  images?: MediaFragment[] | null
  initialIndex?: number
}>()
</script>