<template>
  <div class="tabs">
    <div class="tabs__nav">
      <div class="tabs__nav-items">
        <button v-for="(item, i) in props.list"
          class="btn btn--outline" @click="currentItem = i"
          :class="{ faded: currentItem !== i }"
        >
          {{ item }}
        </button>
      </div>
    </div>
    <div ref="itemsWrapper" class="tabs__items">
      <template v-for="(child, i) in children">
        <div class="tabs__item" :class="{ active: currentItem === i }">
          <component :is="child" />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  list: string[]
}>()

const itemsWrapper = ref<HTMLElement | null>(null)
const currentItem = ref(0)

const slots = useSlots()
const children = slots.default?.()[0].children || []
</script>

<style lang="scss">
.tabs {
  &__nav {
    height: _rem(42px);
    margin-bottom: _rem(32px);
    container-type: inline-size;
  }
  &__nav-items {
    display: flex;
    gap: _rem(8px);
    width: calc(100vw);
    position: absolute;
    left: calc(50% - 50vw);
    padding-left: calc(50vw - 50cqw);
    padding-right: var(--page-margin);
    padding-bottom: _rem(16px);
    overflow-x: auto;
  }

  &__item {
    display: none;
    &.active {
      display: initial;
    }
  }
}
</style>