<template>
  <div class="image-compare" v-intersect="{ onChange: wiggleHandle, observerOptions: { threshold: 0.5 } }">
    <div ref="imagesWrapper" class="image-compare__images">
      <div class="image-compare__after">
        <Image :media="props.after" size="md" />
        <span>{{ $t('images.after') }}</span>
      </div>
      <div ref="beforeImg" class="image-compare__before">
        <Image :media="props.before" size="md" />
        <span>{{ $t('images.before') }}</span>
      </div>
    </div>
    <div ref="slider" class="image-compare__slider">
      <div class="image-compare__slider-handle">
        <Icon name="arrow-range" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap'
import type { MediaFragment } from '#gql'

const props = defineProps<{
  before: MediaFragment
  after: MediaFragment
}>()

const beforeImg = ref<HTMLImageElement | null>(null)
const slider = ref<HTMLElement | null>(null)
const imagesWrapper = ref<HTMLElement | null>(null)

let imagesWrapperRect: DOMRect | null = null

onMounted(() => {
  slider.value?.addEventListener('mousedown', onHandleClicked)
  slider.value?.addEventListener('touchstart', onHandleClicked)
})

onUnmounted(() => {
  slider.value?.removeEventListener('mousedown', onHandleClicked)
  slider.value?.removeEventListener('touchstart', onHandleClicked)
})

const onHandleClicked = () => {
  slider.value!.classList.add('grabbed')
  imagesWrapperRect = imagesWrapper.value?.getBoundingClientRect() || null
  window.addEventListener('mousemove', onHandleMove)
  window.addEventListener('touchmove', onHandleMove)
  window.addEventListener('mouseup', onHandleReleased)
  window.addEventListener('touchend', onHandleReleased)
}

const onHandleMove = (e: MouseEvent | TouchEvent) => {
  const event = 'changedTouches' in e ? e.changedTouches[0] : e

  const x = event.pageX - (imagesWrapperRect?.left || 0) - window.scrollX
  const relativeX = x / (imagesWrapperRect?.width || 1) * 100
  const collapseSliderBar = x < 4 || x > imagesWrapperRect!.width - 4

  collapseSliderBar ? slider.value!.classList.add('collapsed') : slider.value!.classList.remove('collapsed')

  positionHandle(Math.min(Math.max(relativeX, 0), 100))
}

const positionHandle = (pos: number) => {
  slider.value!.style.left = pos + '%'
  beforeImg.value!.style.clipPath = `rect(0 ${pos}% 100% 0)`
}

const onHandleReleased = () => {
  slider.value!.classList.remove('grabbed')

  window.removeEventListener('mousemove', onHandleMove)
  window.removeEventListener('touchmove', onHandleMove)

  window.removeEventListener('mouseup', onHandleReleased)
  window.removeEventListener('touchend', onHandleReleased)
}

const wiggleHandle = () => {
  setTimeout(() => {
    const toTween = { x: 50 }
    gsap.to( toTween, { x: 45, duration: 0.3, ease: "power2.inOut", onUpdate: () => positionHandle(toTween.x) } )
    gsap.to( toTween, { x: 55, duration: 0.6, delay: 0.3, ease: "power2.inOut", onUpdate: () => positionHandle(toTween.x) } )
    gsap.to( toTween, { x: 50, duration: 0.5, delay: 0.9, ease: "power2.inOut", onUpdate: () => positionHandle(toTween.x) } )
  }, 700)
}
</script>

<style lang="scss">
.image-compare {
  position: relative;
  user-select: none;

  &__images {
    position: relative;
    border-radius: var(--box-radius);
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }

    span {
      position: absolute;
      font-size: _rem(14px);
      color: var(--color-white);
      top: _rem(18px);
      right: _rem(18px);
      padding: _rem(8px) _rem(16px);
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 99px;
    }
  }

  &__before {
    @include absolute-cover;
    clip-path: rect(0 50% 100% 0);

    img {
      height: 100%;
    }

    span {
      left: _rem(18px);
      right: auto;
    }
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: _rem(24px);
    height: 100%;
    cursor: ew-resize;
    @include flex-center;

    &::before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: var(--color-white);
      box-shadow: 0 0 _rem(8px) rgba(0, 0, 0, 0.1);
      transition: all 200ms ease;
    }
    &.collapsed::before {
      height: 0;
      opacity: 0;
    }

    &-handle {
      @include flex-center;
      position: absolute;
      width: _rem(48px);
      height: _rem(48px);
      border-radius: 99px;
      background-color: var(--color-white);
      box-shadow: 0 _rem(4px) _rem(16px) rgba(0, 0, 0, 0.2);
      color: var(--color-dark);
      transition: all 100ms ease;
    }
      &.grabbed &-handle {
        opacity: 0;
        transform: scale(0.8);
      }
  }
}
</style>