<template>
  <div class="spacer" :class="props.size ? `spacer--${props.size}` : ''" aria-hidden="true" />
</template>

<script lang="ts" setup>
const props = defineProps<{
  size?: string // 'tiny' | 'small' | 'medium' | 'large' | 'xlarge'
}>()
</script>

<style lang="scss">
.spacer {
  display: block;
  height: _rem(32px);

  &--tiny {
    height: _rem(16px);
  }
  &--small {
    height: _rem(24px);
  }
  &--large {
    height: _rem(48px);
  }
  &--xlarge {
    height: _rem(64px);
  }
}
</style>