<template>
  <PageContentFilter v-if="props.showFilter"
    :title="$t('filter.filter_action')"
    :data="props.data || []"
    :filters="[
      {
        type: 'select',
        name: 'type',
        label: $t('filter.show_all'),
        filterBy: it => it.type?.value,
        labelBy: it => $t(`press.type.${it.type?.value}`)
      }
    ]"
    sticky
    @filter="onFilterChange"
  />

  <Section>
    <FlexGrid :cols="2" :sm-cols="1">
      <Tile v-for="item in filtered"
        :title="item.title"
        :pretitle="$t('press.type.' + item.type?.value)"
        :content="item.content"
      >
        <CTA v-if="item.file" :text="$t(`press.type.${item.type?.value}.download`)" :link="item.file.original_url" icon="download" external outline />
        <CTA v-if="item.cta_text" :text="item.cta_text" :link="item.link" icon="new-window" external outline />
      </Tile>
    </FlexGrid>
  </Section>
</template>

<script lang="ts" setup>
import type { PressItemFragment } from '#gql'

const props = defineProps<{
  data?: PressItemFragment[] | null
  showFilter?: boolean | null
}>()

const filtered = ref<PressItemFragment[]>(props.data || [])
const onFilterChange = (data: PressItemFragment[]) => filtered.value = data
</script>
