<template>
  <div class="anchors" aria-hidden="true">
    <div class="anchors__content">
      <div ref="itemsEl" class="anchors__items">
        <ul ref="itemsListEl">
          <li v-for="(item, i) in props.anchors" 
            :class="[ 'anchors__item', { active: activeItem === i } ]"
            @click="onItemClick(i)"
          >
            <span class="anchors__item-ghost">
              {{ getLabel(item) }}
            </span>
            <a :href="'#' + sanitizedAnchors[i]">
              {{ getLabel(item) }}
            </a>
          </li>
          <li ref="underlineEl" class="anchors__underline" />
        </ul>
      </div>
      <button class="anchors__top-btn" @click="toTop">{{ $t('navigation.to_top') }}</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { sanitizeAnchor } from '~/lib/helpers'

const props = defineProps<{
  anchors: string[]
}>()

let anchorsObserver: IntersectionObserver

const underlineEl = ref<HTMLElement | null>(null)
const itemsEl = ref<HTMLElement | null>(null)
const itemsListEl = ref<HTMLElement | null>(null)
const activeItem = ref<number | null>(null)

let clickedItem: number | null = null

const sanitizedAnchors = computed( () => props.anchors.map(sanitizeAnchor) )

const setupObserver = () => {
  anchorsObserver = new IntersectionObserver( (entries) => {
    const intersectingEntry = entries.find((entry) => entry.isIntersecting)
    const intersectingIndex = intersectingEntry ? sanitizedAnchors.value.indexOf(intersectingEntry.target.id) : null

    // Update active item only if user didn't click on anchor to prevent jumping
    if (clickedItem === null) activeItem.value = intersectingIndex
    else if (clickedItem === intersectingIndex) clickedItem = null
  }, {
    rootMargin: '-50% 0% -50% 0%',
    threshold: 0
  })
}

onMounted(() => {
  setupObserver()

  sanitizedAnchors.value.forEach((anchor) => {
    const el = document.getElementById(anchor)
    if (el) anchorsObserver.observe(el)
  })
})
onUnmounted(() => anchorsObserver?.disconnect() )

watch(activeItem, (value) => {
  if (!underlineEl.value) return

  if (value === null) {
    underlineEl.value.style.width = '0px'
    return
  }

  const item = itemsListEl.value?.children[value] as HTMLElement | undefined

  if (item) {
    itemsEl.value?.scrollTo({ left: item.offsetLeft - itemsEl.value.clientWidth / 2 + item.clientWidth / 2, behavior: 'smooth' })
    underlineEl.value.style.width = `${item.clientWidth}px`
    underlineEl.value.style.transform = `translateX(${item.offsetLeft}px)`
  }
})

const onItemClick = (index: number) => {
  clickedItem = index
  activeItem.value = index
}

const getLabel = (anchor: string) => {
  return anchor
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const toTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
</script>

<style lang="scss">
.anchors {
  --anchors-bg: var(--color-white);
  .hp-hero + & {
    --anchors-bg: var(--color-blue-faint-bg);
  }

  width: 100%;
  // padding: 0 var(--page-margin);
  height: _rem(64px);
  box-shadow: 0px 2px 6.8px 0px rgba(0, 0, 0, 0.07);
  position: sticky;
  background-color: var(--anchors-bg);
  z-index: 100;
  overflow: hidden;
  top: 0;

  .hp-hero + & {
    box-shadow: none;
  }

  &__content {
    height: 100%;
    max-width: var(--page-width);
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: _rem(14px);
    color: var(--color-blue);
    user-select: none;

    @include breakpoint(large down) {
      max-width: calc(var(--page-width) + var(--page-margin) * 2);
    }
  }

  &__items {
    max-width: 100%;
    height: 100%;
    overflow-x: auto;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @supports selector(::-webkit-scrollbar) {
      // &::-webkit-scrollbar {
      //   border: none;
      //   height: 8px;
      // }
      // &::-webkit-scrollbar-thumb {
      //   background: var(--color-gold);
      //   border-radius: 10px;
      // }
      // &::-webkit-scrollbar-track {
      //   margin-left: var(--page-margin);
      // }
    }

    ul {
      display: flex;
      gap: _rem(24px);
      list-style: none;
      padding: 0 var(--page-margin) 0 0;
      margin: 0;
      height: 100%;
      width: max-content;
      position: relative;

      @include breakpoint(large down) {
        padding: 0 var(--page-margin);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;

    &.active {
      font-weight: 500;
    }

    a {
      position: absolute;
    }

    &-ghost {
      visibility: hidden;
      font-weight: 500;
    }
  }

  &__underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: _rem(4px);
    background-color: var(--color-blue);
    transition: all 0.3s;
  }

  &__top-btn {
    padding-right: _rem(20px);
    margin-right: var(--page-margin);
    position: relative;

    @include breakpoint(small down) {
      display: none;
    }

    &::before {
      content: '';
      display: block;
      right: 100%;
      height: 100%;
      width: _rem(48px);
      position: absolute;
      background: linear-gradient(to right, transparent 0%, var(--anchors-bg) 60%);
      pointer-events: none;
    }

    &::after {
      content: '';
      display: block;
      width: _rem(8px);
      height: _rem(8px);
      border-left: 2px solid;
      border-top: 2px solid;
      transform: rotate(45deg);
      position: absolute;
      right: _rem(3px);
      top: calc(50% - _rem(2px));
    }
  }
}
</style>