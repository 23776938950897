<template>
  <div :class="classes" role="note">
    <div class="note__icon">
      <Icon :name="props.type === 'warning' ? 'warning' : 'info'" :size="props.compact ? '' : 'large'" />
    </div>
    <div class="note__text" v-html="props.text">
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  text: string
  type?: 'info' | 'warning'
  color?: string | null
  compact?: boolean
}>()

const classes = computed(() => [
  'note',
  `note--${props.type || 'info'}`,
  `note--${props.color?.toLowerCase() || 'grey'}`,
  props.compact ? 'note--compact' : ''
])
</script>

<style lang="scss">
.note {
  display: flex;
  align-items: center;
  gap: _rem(16px);
  padding: _rem(16px) _rem(24px) _rem(16px);
  background-color: var(--color-dark-8-bg);
  border-radius: var(--box-radius-large);
  color: var(--color-dark);
  font-size: _rem(14px);

  &--dark-grey {
    background-color: var(--color-dark-16-bg);
  }
  &--red {
    background-color: var(--color-red);
    color: var(--color-white);
  }
  &--blue {
    background-color: var(--color-blue);
    color: var(--color-white);
  }
  &--gold {
    background-color: var(--color-gold);
  }

  &--compact {
    padding: _rem(12px) _rem(24px) _rem(12px) _rem(16px);
  }

  @include breakpoint(xsmall down) {
    padding-right: _rem(16px);
  }

  &__icon {
    display: flex;
    align-self: flex-start;
  }

  &__text {
    flex: 1;
  }
}
</style>