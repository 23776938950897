<template>
  <Modal :title="props.title">
    <FlexGrid :cols="props.embedUrl ? 2 : 1" :sm-cols="1" spacing="large">
      <div v-html="props.content" />
      <div v-if="props.embedUrl"><iframe :src="embedUrl" frameborder="0" style="width: 100%; height: 100%;" /></div>
    </FlexGrid>
  </Modal>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title?: string
  content?: string
  embedUrl?: string
}>()
</script>