<template>
  <div class="donation-card">
    <Image class="donation-card__image" :media="props.image" size="xs" />
    <h3 class="donation-card__title">{{ props.title }}</h3>
    <p class="donation-card__description" v-html="props.description" />
  </div>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'

const props = defineProps<{
  image?: MediaFragment | null
  title: string
  description?: string
}>()
</script>

<style lang="scss">
.donation-card {
  border-radius: var(--box-radius-large);
  background-color: var(--color-dark-8-bg);
  user-select: text;
  padding: _rem(32px);

  @include breakpoint(small down) {
    padding: _rem(24px);
  }

  &__image {
    width: 50%;
    height: auto;
    margin-bottom: _rem(40px);
    border-radius: var(--box-radius-large);
    object-fit: contain;
    aspect-ratio: 1;
  }

  &__description {
    font-size: _rem(13px);
  }
}
</style>