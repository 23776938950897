<template>
  <div class="masonry">
    <ClientOnly>
      <MasonryItem v-for="item in placedItems" :data="item" />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import type { MediaFragment } from '#gql'

export interface MasonryItem {
  title?: string
  subtitle?: string
  description?: string
  content?: string
  link?: string
  embedLink?: string
  image?: MediaFragment
  spans?: number
  modelName?: string
}

const props = defineProps<{
  items: MasonryItem[]
}>()

const layoutMaps = {
  a: [11, 12, 11, 12, 12, 11],
  b: [11, 12, 11, 12, 12, 11, 11, 12, 11, 11, 11, 12, 11, 11, 11, 11, 11, 12]
}

const placedItems = computed(() => {
  const items: any[] = []

  layoutMaps.b.forEach((layout, i) => {
    props.items?.[i] && items.push({ ...props.items[i], spans: layout })
  })

  const rest = props.items?.slice(items.length) || []
  
  return [...items, ...rest]
})
</script>

<style lang="scss">
.masonry {
  display: grid;
  gap: var(--grid-gap);
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;

  @include breakpoint(medium down) {
    grid-template-columns: 1fr 1fr;
  }
  @include breakpoint(xsmall down) {
    grid-template-columns: 1fr;
  }
}
</style>