<template>
  <div v-if="slots.default" class="richtext">
    <slot />
  </div>
  <div v-else>
    <div class="richtext" v-html="parsedText[0]" />

    <template v-if="parsedText[1]">
      <div class="richtext__more" :class="{ open: isMoreOpen }">
        <div ref="moreContainer" v-html="parsedText[1]" />
      </div>
      <CTA :text="$t('content.more-information')" icon="chevron-down" outline @click="open" />
    </template>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  text?: string | null
}>()

const slots = useSlots()

const willMount = ref(false)
const moreContainer = ref<HTMLElement | null>(null)
const isMoreOpen = ref(false)
const parsedText = ref([props.text, null])

onBeforeMount( () => willMount.value = true )
onMounted( () => parsedText.value = parseText() )

const open = () => {
  if (!moreContainer.value) return
  const parent = moreContainer.value.parentElement
  parent?.style.setProperty('max-height', `${moreContainer.value.clientHeight}px`)
  isMoreOpen.value = true
  setTimeout(() => parent?.style.setProperty('max-height', 'none'), 400)
}

const parseText = () => {
  if (!willMount.value) return [ props.text, undefined ]

  const parts = props.text?.split('[read-more]') || []
  const [visibleText, collapsedText] = [ parts[0], parts.slice(1).join('') ]

  return [ sanitize(visibleText), sanitize(collapsedText) ]
}

const sanitize = (text: string) => {
  const helper = document.createElement('div')
  helper.innerHTML = text || ''
  helper.querySelectorAll('blockquote').forEach(splitBlockquote)

  return helper.innerHTML.replace(/<p><\/p>/g, '').replace(/&nbsp;</g, '<')
}

const splitBlockquote = (blockquote: HTMLElement) => {
  const textNodes = [...blockquote.childNodes].filter(node => node.nodeName === '#text')
  if (!textNodes.length) return

  const newNodes = textNodes.map(node => {
    const el = document.createElement('blockquote')
    el.textContent = node.textContent
    return el
  })

  const replacementNode = document.createElement('div')
  replacementNode.classList.add('quotes')
  newNodes.forEach(node => replacementNode.appendChild(node))
  blockquote.replaceWith(replacementNode)
}
</script>

<style lang="scss">
.richtext {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: _rem(32px);
    @include heading-font;

    li {
      padding: _rem(18px) 0;
      border-bottom: 1px solid var(--color-dark);

      & + li {
        margin-top: _rem(24px);
      }
    }

    @include breakpoint(medium down) {
      font-size: _rem(24px);

      li {
        padding: _rem(12px) 0;
        & + li {
          margin-top: _rem(16px);
        }
      }
    }
  }

  &__more {
    max-height: 0;
    margin-top: _rem(16px);
    overflow: hidden;
    opacity: 0;
    transition:
      opacity 200ms ease 100ms,
      max-height 600ms var(--ease-smooth-in);

    &.open {
      opacity: 1;
      transform: none;
      + .btn {
        display: none;
      }
    }
  }

  .quotes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: _rem(16px);

    &:not(:first-child) {
      margin-top: _rem(36px);
    }

    blockquote {
      padding: _rem(16px) 0;
      border-top: 1px solid var(--color-dark-16-lines);
    }
  }
}
</style>
