<template>
  <component
    ref="el"
    :is="_data.link ? 'a' : 'div'"
    :href="_data.link || undefined"
    :target="_data.link?.startsWith('http') ? '_blank' : undefined"
    :class="classes"
    @click="handleClick"
  >
    <template v-if="isTextual">
      <Icon name="info" size="xlarge" />
    </template>
    <template v-else>
      <Image :media="_data.image" size="md" />
      <div class="masonry-item__overlay" />
    </template>
    <div class="masonry-item__content">
      <div class="masonry-item__base-text">
        <h3>{{ _data.title }}</h3>
        <p>{{ _data.subtitle }}</p>
      </div>
      <div v-if="showDescription" class="masonry-item__description" v-html="clearTextFormatting(_data.description || _data.content || '')" />
    </div>
  </component>
</template>

<script lang="ts" setup>
import type { MasonryItem } from './Masonry.vue'
import { clearTextFormatting } from '~/lib/helpers'
import { openTextAndImageModal, openModal, openIframeModal } from '~/lib/modals'

const props = defineProps<{
  data: MasonryItem
}>()

const _data = ref<MasonryItem>(props.data)
const el = ref<HTMLElement | null>(null)

// Animate on data change
watch(() => props.data, () => {
  el.value?.classList.add('will-update')
  setTimeout(() => {
    _data.value = props.data
    el.value?.classList.remove('will-update')
  }, 200)
})

onMounted(() => {
  setTimeout(() => el.value?.classList.remove('will-update'), 100)
})

const spansTwoCols = computed( () => _data.value.spans && [2, 21, 22].includes(_data.value.spans) )
const spansTwoRows = computed( () => _data.value.spans && [12, 22].includes(_data.value.spans) )

const isTextual = computed( () => _data.value.modelName === 'TopicCard' )
const shouldOpenModal = computed( () => [ 'CollectionItem', 'TopicCard' ].includes(_data.value.modelName as string) )
const showDescription = computed( () => ![ 'Event' ].includes(_data.value.modelName as string) )

const handleClick = (e) => {
  if (shouldOpenModal.value) {
    e.preventDefault()

    if (isTextual.value) {
      openModal(_data.value.title || '', _data.value.content || '')
    } else {
      openTextAndImageModal(_data.value.title, _data.value.description, _data.value.image)
    }
  } else if (_data.value.embedLink) {
    e.preventDefault()
    openIframeModal(_data.value.embedLink as string)
  }
}

const classes = computed(() => [
  'masonry-item',
  { 'masonry-item--2-cols': spansTwoCols.value },
  { 'masonry-item--2-rows': spansTwoRows.value },
  { 'masonry-item--interactive': showDescription.value },
  { 'masonry-item--textual': isTextual.value },
  'will-update'
])
</script>

<style lang="scss">
.masonry-item {
  display: flex;
  padding: _rem(24px);
  border-radius: var(--box-radius);
  position: relative;
  color: var(--color-white);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 200ms ease;

  &--textual {
    color: inherit;
    outline: 1px solid var(--color-dark-16-lines);

    .icon--info {
      top: _rem(22px);
      left: _rem(22px);
      position: absolute;
    }
  }

  &.will-update {
    opacity: 0;
    transform: scale(0.95);
    transition-delay: 0ms;
  }

  &::before {
    content: "";
    display: block;
    position: relative;
    padding-top: 76.5%;
    width: 100%;
  }

  &::after {
    content: "";
    @include absolute-cover;
    border-radius: var(--box-radius);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, transparent 180px);
    mix-blend-mode: overlay;
    z-index: -1;
  }
    &--textual::after { display: none }

  @include breakpoint(small up) {
    &--2-cols {
      grid-column: auto / span 2;
      &::before { padding-top: 34%; }
    }
    &--2-rows {
      grid-row: auto / span 2;
      &::before { padding-top: 173%; }
    }
    &--2-rows#{&}--2-cols {
      &::before { padding-top: 76.5%; }
    }
  }

  img {
    @include absolute-cover;
    border-radius: var(--box-radius);
    object-fit: cover;
    z-index: -1;
  }

  &__overlay {
    @include absolute-cover;
    border-radius: var(--box-radius);
    transition: all 400ms ease;

    &::after {
      content: "";
      @include absolute-cover;
      border-radius: var(--box-radius);
      background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, transparent 180px);
      transition: all 600ms ease;
    }
  }
    &--interactive:hover &__overlay {
      backdrop-filter: blur(4px);
      background-color: rgba(0, 0, 0, 0.25);
      &::after { opacity: 0; }
    }

  &__content {
    top: 0;
    height: 100%;
    position: absolute;
    width: calc(100% - _rem(48px));
    display: flex;
    flex-direction: column;
    justify-content: end;
    container-type: size;
    overflow: hidden;
  }
    &--textual &__content {
      padding: _rem(80px) 0 _rem(24px);
      justify-content: space-between;
    }

  &__base-text {
    position: absolute;
    bottom: _rem(24px);
    transition: transform 300ms ease;
    text-shadow: var(--text-shadow);

    h3 {
      margin: 0;
    }
    p {
      margin-top: _rem(6px);
    }
  }
    &:hover h3 { text-decoration: underline }
    &--interactive:hover &__base-text {
      transform: translate3d(0, calc(100% - 100cqh + _rem(48px)), 0);
      h3 { text-decoration: none }
    }
    &--textual &__base-text {
      position: static;
      // margin: _rem(24px) 0 0;
    }

  &__description {
    top: 100%;
    position: absolute;
    transition: opacity 300ms ease, transform 300ms ease;
    opacity: 0;
    @include line-clamp(5);
  }
    &--2-rows &__description {
      @include line-clamp(12);
    }
    &--interactive:hover &__description {
      transform: translate3d(0, calc(-100% - _rem(24px) + 0.01cqh), 0);
      opacity: 1;
    }
    &--textual &__description {
      position: static;
      opacity: 1;
    }
}
</style>